

import {useState, useEffect } from "react";
// Chakra imports
import { Flex } from "@chakra-ui/react";
import { ApiHeader } from '../../../_helpers/ApiHeader';

// Custom components
import Card from "../../../components/card/Card";
import Banner from "../../../views/request/payPageSdk/components/Banner";
import Content from "../../../views/request/payPageSdk/components/Content";

export default function PayPageSDK() {
  // Chakra Color Mode


  return (
    // <Card maxW='540px' mx='auto'>
    //     <Content/>
    // </Card>
    <Card mt={{ base: "20px", md: "20px", xl: "20px" }} maxW='540px' mx='auto'>
      <Flex direction='column' width='stretch'>
        {/* <Banner title='PayPangea'/> */}
        <Content/>
      </Flex>
    </Card>
  );
}
