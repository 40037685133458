import { 
    Text, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    Button, 
    Flex,
    useToast,
    GridItem } from "@chakra-ui/react";
import { CheckCircleIcon, WarningTwoIcon } from "@chakra-ui/icons";
import React from "react";

import { HSeparator } from "../../../components/separator/Separator";

const SuccessModal = ({isOpen, onClose, pkey, message = ''}) => {
    const toast = useToast();
  return (
    <Modal 
        isCentered 
        motionPreset='slideInBottom'
        isOpen={isOpen} 
        onClose={onClose} 
        size="xs">
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropBlur='8px'/>
        <ModalContent>
            <ModalHeader textAlign="center">Private key</ModalHeader>
            <ModalCloseButton />
            <ModalBody p={10} textAlign="center">
                <Text
                fontSize='sm'
                color='white' 
                mb='15px'
                fontWeight='bold'>
                 Unauthorised and uninformed distribution of your private key will most likely result in loss of funds.
                </Text>
                <WarningTwoIcon color="red.500" boxSize={40} mb={6} />
                <Flex
                    bottom='30px'
                    w='calc(100% - 40px)' 
                    wrap={'wrap'}
                    left='20px' >
                    <Flex 
                        color='white' 
                        textAlign='left' 
                        direction="row"
                        w='calc(100%)' 
                        justifyContent={'space-between'}
                        alignItems={'baseline'}>
                        <Text
                        fontSize='sm'
                        color='white' 
                        fontWeight='bold'>
                        {'Private key'}
                        </Text>
                        <Button
                        variant="outline"
                        borderRadius="full"
                        color='white' 
                        mb='10px'
                        size="xs"
                        onClick={() => {
                            navigator.clipboard.writeText(pkey)
                            toast({
                                title: 'Success.',
                                description: "Private key copied to clipboard",
                                status: 'success',
                            })
                        }}
                        >
                        Copy
                        </Button>
                    </Flex>
                    <Text 
                        color='white' 
                        textAlign='left' 
                        w='calc(100%)' 
                        fontSize={{ sm: "md", lg: "2vw", xl: "1vw" }}
                        fontWeight='bold'>
                        {pkey}
                    </Text>
                    
                    </Flex>
                <Button onClick={onClose} 
                    variant='brand' 
                    mt="20px" 
                    size="lg" 
                    w="100%">
                    Close
                </Button>

            </ModalBody>
          </ModalContent>
    </Modal>
  );
}

export default SuccessModal;
