import {useState, useEffect} from "react";
import { useNavigate } from 'react-router';

import transakSDK from '@transak/transak-sdk';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useDisconnect, useWeb3ModalProvider, useWeb3ModalAccount, useWeb3ModalEvents } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers'

// Chakra imports
import { 
  Button,
  Input, 
  Box,
  Link,
  Image,
  Flex, 
  Text, 
  useToast,
  useBreakpointValue,
  Icon, 
  IconProps,
  GridItem,
  Grid,
  useColorModeValue } from "@chakra-ui/react";

// Custom components

import EVENTS from '../../../../_helpers/events';
import { GetUserData } from '../../../../_helpers/Auth';
import LoginModal from '../../../../views/modals/authLogin/login';
import PinInputModal from '../../../../views/modals/pin/pin';
import Overlay from '../../../../views/modals/overlay/overlay';
import TopUpModal from '../../../../views/modals/topup/topup';
import WalletTopUpModal from '../../../../views/modals/walletconnecttopup/walletconnecttopup';

import SetPinModal from '../../../../views/modals/setcode/setcode';
import Card from "../../../../components/card/Card.js";

import payPangeaLogoBlack from "../../../../assets/img/PayPangeaLogoBlack.png";
import payPangeaLogoWhite from "../../../../assets/img/PayPangeaLogoWhite.png";

import { ApiHeader } from '../../../../_helpers/ApiHeader';

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import InvoiceBg from "../../../../assets/img/InvoiceBg.png";


const projectId = 'de93e60aa3956c4d46ac74104bfd4415'

const erc20Abi = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function balanceOf(address) view returns (uint)",
  "function transfer(address to, uint amount)",
  "event Transfer(address indexed from, address indexed to, uint amount)"
];

// 2. Set chains
const WalletChain = {
  chainId: 137, // Polygon Mainnet's chain ID
  name: 'Polygon',
  currency: 'MATIC', // The native currency of Polygon
  explorerUrl: 'https://polygonscan.com', // Polygon blockchain explorer
  rpcUrl: 'https://polygon-rpc.com' // Public RPC for Polygon
}

// 3. Create modal
const metadata = {
  name: 'PayPangea',
  description: 'Decentralised payments made easy',
  url: 'https://my.paypangea.com',
  icons: ['https://my.paypangea.com/logo192.png']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [WalletChain],
  projectId,
  tokens: {
    137: {
      address: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359'
    }
  }
})

// Assets

export default function Content(props) {
  const { ...rest } = props;


  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();

	const payPangeaLogo = useColorModeValue(payPangeaLogoBlack, payPangeaLogoWhite);

  const toast = useToast();
  const navigate = useNavigate();
  // Chakra Color Mode
  const bgCard = useColorModeValue("white", "navy.700");


  const currentPageURL = window.location.search;
  const searchParams = new URLSearchParams(currentPageURL);

  const requestTkn = searchParams.get('tkn'); 
  const showPaymentData = searchParams.get('show') === 'true'; 
  const showWalletConnect = searchParams.get('walletconnect') !== 'false'; 

  const [wallet, setWallet] = useState('');
  const [receiverWallet, setReceiverWallet] = useState('');
  const [amount, setAmount] = useState('');
  const [amountCrypto, setAmountCrypto] = useState('');
  const [currency, setCurrency] = useState('');
  const [token, setToken] = useState('');
  const [walletUsername, setWalletUsername] = useState('');
  const [isUserVerified, setIsUserVerified] = useState(true);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('Processing ...');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payTkn, setPayTkn] = useState('');
  const [cardPayTkn, setCardPayTkn] = useState('');
  const [isOverlay, setIsOverlay] = useState(false);

  const [loginOutcome, setLoginOutcome] = useState('');

  const walletDisplayText = useBreakpointValue({
    base: `${receiverWallet.substr(0,8)}...${receiverWallet.slice(-6)}`,
    md: receiverWallet,
  });

  const sendMessageToParent = (message) => {
    window.parent.postMessage(message, "*");
  };

  useEffect(() => {
    sendMessageToParent({
      event_id:EVENTS.PAYPANGEA_WIDGET_OPEN
    });
  }, []);

  const closeWidget = (message) => {
    console.log(message);
    sendMessageToParent({
        event_id:EVENTS.PAYPANGEA_WIDGET_CLOSE_REQUEST,
        outcome: message,
      });
  }

  const openModalWindow = (name) => {
    console.log(name);
    setIsLoginOpen(name === 'loginModal');
    setIsPinModalOpen(name === 'pinModal');
    setIsOverlay(name === 'overlay');

    if (name==='walletConnectopen') {
      open();
    }
  }

  const verifyAddress = (address) => {
    if (address === '') {
      toast({
        title: 'Error.',
        description: "Address can not be empty",
        status: 'error',
      })
      return;
    }

    fetch(process.env.REACT_APP_API_URL+'auth/address-auth', {
      method: 'POST',
      body: JSON.stringify({
        "wallet" : address
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)
      if (data.status === 200) {
        closeWidget({code: 200, wallet: address, token:data.token, message: 'OK'});
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: "Error, please try again",
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
    });
  }

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const event = useWeb3ModalEvents();

  useEffect(() => {
    console.log(event.data.event)

    if (event) {
       if (event.data.event === 'SELECT_WALLET') {
       }
       if (event.data.event === 'CONNECT_SUCCESS') {
        setFirstTime(false);
       }
       if (event.data.event === 'MODAL_CLOSE') {
        setTimeout(() => {
            setFirstTime(false);
        }, 500);
       }
       if (event.data.event === 'MODAL_OPEN') {
       }
   };
 }, [event]);

 const [firstTime, setFirstTime] = useState(true);

 useEffect(() => {
    console.log(`chainId ${chainId}`);
    console.log(`address ${address}`);
    console.log(`isConnected useEffects ${isConnected}`);
    console.log(`firstTime ${firstTime}`);
    if (isConnected) {
        if (!isLoginOpen) {
          verifyAddress(address)
        }
   };
 }, [isConnected, firstTime]);


  const openWalletConnect = async () => { 
    console.log("wallet connect");

    if (!isConnected) {
        openModalWindow('walletConnectopen');
    } else {
        disconnect();
        setTimeout(() => {
            openModalWindow('walletConnectopen');
        }, 1000);
    }

  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onDisconnect = () => {
    console.log('disconnect');

    console.log(`chainId ${chainId}`);
    console.log(`address ${address}`);
    console.log(`isConnected ${isConnected}`);

    // if (chainId === 0 || !isConnected) {
    //   openModalWindow('walletConnectopen');
    // } else {
    disconnect();

    console.log(`isConnected ${isConnected}`);
    // }
  };

  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const closeLoginModal = (isNew) => {
    console.log(isNew);

    const userData = GetUserData();
    console.log("userData")
    console.log(userData)

    setIsLoginOpen(false);

    if (userData!==null) {
      setWallet(userData.address)
    } else {
        toast({
          title: 'Error.',
          description: "Something went wrong, please try again",
          status: 'error',
        })
        return;
    }

    if (isNew!==undefined) {
      if (!isNew) {
        console.log('logedin, show address');
        verifyAddress(userData.address);
      } else {
        openPinModal();
      }
    }

  };

  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  const openPinModal = () => {
    openModalWindow('pinModal');
    // setIsPinModalOpen(true);
  };

  const closePInModal = () => {
    setIsPinModalOpen(false);
  };

  const pinSet = (address) => {
    console.log(address);
    setWallet(address);
    closePInModal();
    console.log('logedin, show address')
    verifyAddress(address)
  }

  useEffect(()=>{
    openModalWindow('loginModal')
  },[])



  return (
    <>
        <Flex direction='column' h='90vh' align='center' justify='center'>
            <Card
            backgroundRepeat='no-repeat'
            bg={bgCard}
            p='30px'
            mb='20vh'
            w='fit-content'>
            </Card>
        </Flex>

        <LoginModal isOpen={isLoginOpen} onClose={closeLoginModal} openWalletConnect={openWalletConnect} showWalletConnect={showWalletConnect}/>

        <SetPinModal isOpen={isPinModalOpen} onClose={closePInModal} pinSet={pinSet}/>

        <Overlay isOpen={isOverlay} text={text}/>
    </>
  );
}
