import React from 'react';
import { Box, Heading, Text, List, ListItem, Flex } from '@chakra-ui/react';
import DefaultAuth from "../../../layouts/auth/auth";

import illustration from "../../../assets/img/auth.png";
import illustrationMobile from "../../../assets/img/authMobile.png";

const TermsOfUseChakra = () => {
  return (
    <>
      <DefaultAuth illustrationBackground={illustration} illustrationBackgroundMobile={illustrationMobile} image={illustration}>
        <Box
            maxW={{ base: "100%", md: "max-content" }}
            w={{ base: "100%", lg: "40%" }}
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}>
              <Heading as="h1" size="xl" mb={4}>Terms of Use for PayPangea </Heading>
              <Text mb={2}><strong>Last Updated:</strong> 01. Dec. 2023</Text>

              <Text mb={4}>Welcome to PayPangea ("PayPangea", "we", "us", or "our"). The PayPangea, along with its related services, software, and websites (collectively, the "Service"), is provided to you ("User", "you", or "your") subject to the terms and conditions contained in this Terms of Use Agreement ("Agreement"). By accessing, using, or downloading any part of the Service, you agree to be bound by this Agreement. If you do not agree to all the terms and conditions of this Agreement, you may not use the Service.</Text>

              <Heading as="h2" size="lg" mb={2}>1. Acceptance of Terms</Heading>
              <Text mb={2}>1.1 By using the PayPangea, you agree to these terms and any policies referenced within, including terms that limit our liability and require individual arbitration for any potential legal dispute. You also agree to comply with all applicable laws and regulations.</Text>
              <Text mb={4}>1.2 We reserve the right to update or modify these Terms at any time without prior notice, and such changes will be effective immediately upon being posted on this page. Your use of the Service following any such change constitutes your agreement to follow and be bound by the Terms as changed.</Text>

              <Heading as="h2" size="lg" mb={2}>2. Service Description</Heading>
              <Text mb={4}>PayPangea is a digital payment and wallet service that allows users to store, send, and receive digital assets, including cryptocurrencies. The Service does not have custody of your assets; you are solely responsible for managing your wallet's private keys and any transactions or activities conducted through your wallet.</Text>

              <Heading as="h2" size="lg" mb={2}>3. User Responsibilities</Heading>
              <Text mb={2}>3.1 Security: You are responsible for safeguarding the password and all private keys you use to access the Service. You are solely responsible for any activities or actions under your wallet, whether or not you have authorized such activities or actions.</Text>
              <Text mb={2}>3.2 Compliance: You agree to comply with all local, state, national, and international laws and regulations applicable to your use of the Service.</Text>
              <Text mb={4}>3.3 Accuracy of Information: You agree to provide accurate, current, and complete information as necessary for the use of the Service and to update such information to keep it accurate, current, and complete.</Text>

              <Heading as="h2" size="lg" mb={2}>4. Prohibited Use</Heading>
              <Text mb={2}>You agree not to use the Service in any way that:</Text>
              <List mb={4} pl={4}>
                <ListItem>Violates any law or regulation;</ListItem>
                <ListItem>Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</ListItem>
                <ListItem>Jeopardizes the security of your PayPangea  or anyone else's;</ListItem>
                <ListItem>Attempts, in any manner, to obtain the password, account, or other security information from any other user.</ListItem>
              </List>

              <Heading as="h2" size="lg" mb={2}>5. Intellectual Property Rights</Heading>
              <Text mb={4}>All intellectual property rights in the Service, its content, and the technology related to the Service are owned by Microclash Ltd or its licensors. You may not copy, modify, distribute, sell, or lease any part of our Services or included software, nor may you reverse engineer or attempt to extract the source code of that software, without our written permission.</Text>

              <Heading as="h2" size="lg" mb={2}>6. Disclaimers</Heading>
              <Text mb={4}>The Service is provided "as is" and "as available" without any warranties, representations, or guarantees of any kind. Microclash Ltd disclaims all express and implied warranties including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.</Text>

              <Heading as="h2" size="lg" mb={2}>7. Limitation of Liability</Heading>
              <Text mb={4}>To the fullest extent permitted by applicable law, Microclash Ltd shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</Text>

              <Heading as="h2" size="lg" mb={2}>8. Arbitration</Heading>
              <Text mb={4}>Any dispute, claim, or controversy arising out of or relating to this Agreement or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall be determined by arbitration in [Location], before one arbitrator.</Text>

              <Heading as="h2" size="lg" mb={2}>9. Governing Law</Heading>
              <Text mb={4}>This Agreement shall be governed by and construed in accordance with the laws of Gibraltar, without giving effect to any principles of conflicts of law.</Text>

              <Heading as="h2" size="lg" mb={2}>10. Contact Information</Heading>
              <Text mb={4}>If you have any questions about these Terms, please contact us at info@paypangea.com.</Text>

              <Text mb={4}>By using the PayPangea, you acknowledge that you have read these Terms of Use and agree to be bound by them.</Text>

              <Text mb={4} height="50px"></Text>
            </Box>
      </DefaultAuth>
    </>
    
  );
}

export default TermsOfUseChakra;
