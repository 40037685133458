import {useState, useEffect, useCallback} from "react";
import { useNavigate } from 'react-router'
import QRCode from 'qrcode.react';
import validator from "validator";


// Chakra imports
import { 
  Heading,
  FormLabel,
  Button,
  Input, 
  Flex, 
  Text, 
  useToast,
  Grid,
  GridItem,
  useBreakpointValue,
  useColorModeValue } from "@chakra-ui/react";

// Custom components

import PinInputModal from '../../../../views/modals/pin/pin';
import { HSeparator } from "../../../../components/separator/Separator";
import OutcomeModal from '../../../../views/modals/outcome/outcome';
import Overlay from '../../../../views/modals/overlay/overlay';
import Card from "../../../../components/card/Card.js";
import InputField from "../../../../components/fields/InputField";
import TextField from "../../../../components/fields/TextField";
import ButtonSelect from "../../../../components/buttonSelect/ButtonSelect";
import { ApiHeader } from '../../../../_helpers/ApiHeader';

// Assets

export default function Content(props) {
  const { wallet, ...rest } = props;

  const supportedNetworks = [
    'polygon',
    'mainnet',
    'BNB',
    'Arbitrum One',
    'Avalanche',
    // 'pangea',
    'sepolia',
  ]

  const toast = useToast();
  const navigate = useNavigate();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgCard = useColorModeValue("white", "navy.700");
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const [selectedNetwork, setSelectedNetwork] = useState(supportedNetworks[0]);
  const [availableTokens, setAvailableTokens] = useState([]);
  const [availableTokensSymbols, setAvailableTokensSymbols] = useState([]);
  const [selectedToken, setSelectedToken] = useState('');
  const [selectedTokenAddress, setSelectedTokenAddress] = useState('');
  const [requestTitle, setRequestTitle] = useState('');
  const [requestText, setRequestText] = useState('');

  const [amountPay, setAmountPay] = useState('');
  const [recepient, setRecepient] = useState('');

  const [paymentLink, setPaymentLink] = useState('');
  const [requestToken, setRequestToken] = useState('');

  const [nfcData, setNfcData] = useState({});

  const payPath = window.location.href.split('?')[0].split('#')[0].replace('request-payment', 'pay').replace('admin', 'request')

  const [startPayment, setStartPayment] = useState(false);

  const [payOutcome, setPayOutcome] = useState('');
  const [outcomeMessage, setOutcomeMessage] = useState('');
  const [isOverlay, setIsOverlay] = useState(false);

  const [overlayText, setOverlayText] = useState('Coming soon');

  const handleOverlay = (timeWait) => {
    setIsOverlay(true);

    setTimeout(() => {
      // After 1 second (1000 milliseconds), update the state to show content
      setIsOverlay(false);
    }, timeWait);
  };

  const [isOutcomeModalOpen, setIsOutcomeModalOpen] = useState(false);

  const [receiverWallet, setReceiverWallet] = useState('');
  const [network, setNetwork] = useState('');
  const [amount, setAmount] = useState('');
  const [token, setToken] = useState('');

  const openOutcomeModal = () => {
    setIsOutcomeModalOpen(true);
  };

  const closeOutcomeModal = () => {
    setIsOutcomeModalOpen(false);

    navigate(`/admin/home`);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isEthereumAddress = (input) => {
    // Ethereum address validation logic (same as in the previous Node.js example)
    return /^0x[0-9a-fA-F]{40}$/.test(input);
  };


  useEffect(()=>{
    getAvailableTokens(selectedNetwork)
  },[selectedNetwork])

  const clickRequest = () => {
    console.log(`click pay ${recepient}`);
    console.log(`request pay ${paymentLink}`);

    if (validator.isEmail(recepient)) {
      console.log('Email');
      sendRequestEmail(requestToken, recepient, paymentLink);
    } else if (validator.isMobilePhone(recepient, 'any', { strictMode: true })) {
      console.log('phone number');
    } else if (validator.isMobilePhone(recepient, 'any', { strictMode: false })) {
      toast({
        title: 'Error.',
        description: "Phone number must be in interantional format with + and country code",
        status: 'error',
      })
    } else if (isEthereumAddress(recepient)) {
      console.log('Ethereum address');
    } else {
      toast({
        title: 'Error.',
        description: "Unknown format, you can send crypto to email, phone or wallet address",
        status: 'error',
      })
    }
  };

  const getAvailableTokens = (network) => {

    if (!supportedNetworks.includes(network)) {
      return;
    }

    fetch(process.env.REACT_APP_API_URL+'pay/request-get-tokens', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : network
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data.tokens)
        setAvailableTokens(data.tokens)
        const avTokens = data.tokens.map(token => token.symbol)
        setAvailableTokensSymbols(avTokens)

        console.log(avTokens)

        const token = data.tokens.find(t => t.symbol === 'USDC');

        console.log(token)
        const addressUSDC = token ? token.address : '';
        const tokenName = token ? 'USDC' : '';

        console.log(tokenName)
        console.log(addressUSDC)

        setSelectedToken(tokenName)
        setSelectedTokenAddress(addressUSDC)

        if (data.tokens.length === 0 && !isOverlay) {
          setOverlayText('Coming soon')
          handleOverlay(1000);
        }
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const generateRequest = () => {
    fetch(process.env.REACT_APP_API_URL+'pay/request-pay', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : selectedNetwork,
        "amount": amountPay,
        "token": selectedToken,
        "tokenaddress": selectedTokenAddress,
        "title": requestTitle,
        "text": requestText,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data)
        setRequestToken(data.tkn)

        setPaymentLink(`${payPath}/?tkn=${data.tkn}&show=true`)
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const [cardToken, setCardToken] = useState('');
  const [payTkn, setPayTkn] = useState('');


  const startCardPay = (rt, cardnr, ccv) => {
    setOverlayText('Processing...')
    setIsOverlay(true);
    fetch(process.env.REACT_APP_API_URL+'pay/pay-by-card', {
      method: 'POST',
      body: JSON.stringify({
        "tkn" : rt,
        "card": cardnr,
        "ccv": ccv,
      }),
      headers: {'Content-Type': 'application/json'}
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsOverlay(false);
      console.log(data)

      if (data.status === 1) {
        console.log(data)
        setCardToken(data.token)
        setPayTkn(data.paymentData.tkn)
        setAmount(data.paymentData.amount)
        setReceiverWallet(data.paymentData.receiver)
        openModal();
      } else if (data.status === 2) {
        setPayOutcome('FAILURE');
        setOutcomeMessage(`${data.message}.\nPlease top up your account.`)
      }
      
    })
    .catch(err => {
      setIsOverlay(false);
        console.log("fetch error: " + err);
    });
  }

  const submitPin = (submitedPin) => {
    console.log(`submitedPin ${submitedPin}`)
    setIsModalOpen(false);
    confirmPay(submitedPin)
  };


  const confirmPay = (pin) => {
    setOverlayText('Processing...')
    setIsOverlay(true);

    fetch(process.env.REACT_APP_API_URL+'pay/verify-by-card', {
      method: 'POST',
      body: JSON.stringify({
        "tkn": payTkn,
        "token": cardToken,
        "passkey": pin,
      }),
      headers: {'Content-Type': 'application/json'}
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        setPayOutcome('SUCCESS');
      } else if (data.status === 2) {
        setPayOutcome('FAILURE');
        setOutcomeMessage(`${data.message}.\nPlease top up your account.`)
      } else {
        setPayOutcome('FAILURE');
      }
      openOutcomeModal(true);
      setIsOverlay(false);
    })
    .catch(err => {
        console.log("fetch error: " + err);

      setIsOverlay(false);
        toast({
          title: 'Error.',
          description: "Something went wrong, please try again",
          status: 'error',
        })
    });
  }


  const sendRequestEmail = (tkn, email, link) => {
    setOverlayText('Processing...')
    setIsOverlay(true);
    fetch(process.env.REACT_APP_API_URL+'pay/send-request-email', {
      method: 'POST',
      body: JSON.stringify({
        "tkn" : tkn,
        "email": email,
        "link": link,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsOverlay(false);
      console.log(data)

      if (data.status === 1) {
        toast({
          title: 'Success.',
          description: "Email request send.",
          status: 'success',
        })
      } else {
        toast({
          title: 'Error.',
          description: "Something went wrong, please try again",
          status: 'error',
        })
      }
      
    })
    .catch(err => {
      setIsOverlay(false);
      toast({
        title: 'Error.',
        description: "Something went wrong, please try again",
        status: 'error',
      })
    });
  }

  useEffect(()=>{


    if (requestToken && requestToken!=='') {
      ScanForNFC();


      // const NFCData = JSON.parse('{"card":"5412897653248975","ccv":"5493"}');
  
      // startCardPay(NFCData.card, NFCData.ccv);
    }
  },[requestToken])


  useEffect(()=>{

    if (nfcData && nfcData.card && nfcData.ccv) {
      startCardPay(requestToken, nfcData.card, nfcData.ccv);
    }
    
  },[requestToken, nfcData])

  const ScanForNFC = useCallback(async() => {

    if ('NDEFReader' in window) { 
        try {

            setNfcData({});

            const ndef = new window.NDEFReader();
            await ndef.scan();
            
            console.log("Scan started successfully.");
            ndef.onreadingerror = () => {
                console.log("Cannot read data from the NFC tag. Try another one?");
            };
            
            ndef.onreading = event => {
                console.log("NDEF message read.");
                onReading(event);
            };

        } catch(error){
            console.log(`Error! Scan failed to start: ${error}.`);
        };
    } else {
      console.log(`NFC not supported ${requestToken}.`);

    }
},[]);

const onReading = ({message, serialNumber}) => {
    for (const record of message.records) {
        switch (record.recordType) {
            case "text":
                const textDecoder = new TextDecoder(record.encoding);
                const NFCs = textDecoder.decode(record.data);

                try {
                  const NFCData = JSON.parse(NFCs);

                  setNfcData(NFCData)

                } catch (e) {
                  console.log(`error 1 ${e.toString().substring(0, 512)}`)
                }
                break;
            case "url":
                // TODO: Read URL record with record data.
                break;
            default:
                // TODO: Handle other records with record data.
            }
    }
};


  const handleNumberClick = (number) => {
    if ((selectedNetwork === '' || selectedToken === '') && !isOverlay) {
      setOverlayText('Select the Network and Token first')
      handleOverlay(2000);
      return;
    }

    setAmountPay(amountPay + number);
    setRequestToken('');
  };

  const handleSetDecimal = () => {
    if (!amountPay.includes('.')) {
      setAmountPay(amountPay + '.');
      setRequestToken('');
    }
  };

  const handleClearClick = () => {
    setAmountPay('');
    setRequestToken('');
  };

  return (
    <>
      <Flex direction='column' p={{base:"10px", md:"60px"}}>
        <Card
          backgroundRepeat='no-repeat'
          bg={bgCard}
          p={{base:"30px 10px", md:"30px"}}
          mb='30px'
          mt='-100px'>
          <Flex direction={{base:"column", md:"row"}} 
            gap='20px'>
            <Flex 
            direction='row' 
            me='auto' 
            width={{base:"100%", md:"50%"}}
            minWidth={"50%"}
            mb={{base:"30px", md:"0px"}}>
              <Flex direction='column' >

                {requestToken !=='' && (
                  <Flex direction="column" justifyContent="space-between" mb='20px'>
                      <Text fontSize='xl' fontWeight='700' ml={0} mb={10}>
                        {amountPay} {selectedToken} 
                        <Text as="span" fontWeight='400' ml='5px' display="inline">
                          on {selectedNetwork}
                        </Text>
                      </Text>
                      <div style={{ background: 'white', padding: '15px', margin: "auto"}}>
                        <QRCode value={`${paymentLink}`} size={240} />
                      </div>
                      <Button
                        w='100%'
                        mt='20px'
                        fontSize='md'
                        p='6px 12px'
                        bg='linear-gradient(108.54deg, #FF416C 6.56%, #FF4B2B 95.2%)'
                        color='white'
                        textAlign='center'
                        borderRadius='10px'
                        fontWeight='700'
                        onClick={()=>setRequestToken('')}>
                          Cancel request
                      </Button>
                      <Flex align='center' mt='25px' mb='25px'>
                        <HSeparator />
                        <Text color='gray.400' mx='14px'>
                          or
                        </Text>
                        <HSeparator />
                      </Flex>


                      <Flex 
                      color='white' 
                      textAlign='left' 
                      direction="row"
                      w='calc(100%)' 
                      justifyContent={'space-between'}
                      alignItems={'baseline'}>
                        <Text fontSize='sm' fontWeight='700' mb='5px'>
                          Share this link to collect payment: 
                        </Text>
                        <Button
                          variant="outline"
                          borderRadius="full"
                          mb='10px'
                          size="xs"
                          onClick={() => {
                            navigator.clipboard.writeText(paymentLink)
                            toast({
                              title: 'Success.',
                              description: "Link copied to clipboard",
                              status: 'success',
                            })
                          }}
                        >
                          Copy link
                        </Button>
                      </Flex>

                      <Text 
                        fontSize='sm' 
                        cursor='pointer'
                        fontWeight='400' 
                        onClick={() => {
                          navigator.clipboard.writeText(paymentLink)
                          toast({
                            title: 'Success.',
                            description: "Link copied to clipboard",
                            status: 'success',
                          })
                        }}
                        mb='15px'>
                        {paymentLink}
                      </Text>
                      <InputField
                        mb='24px'
                        mt='8px'
                        id='recepient'
                        label='or send a request to customers email:'
                        placeholder='customer'
                        onChange={(e) => setRecepient(e.target.value)}
                      />
                      <Button
                        w='100%'
                        fontSize='md'
                        p='6px 12px'
                        bg='linear-gradient(108.54deg, #FF416C 6.56%, #FF4B2B 95.2%)'
                        color='white'
                        textAlign='center'
                        borderRadius='10px'
                        fontWeight='700'
                        onClick={()=>clickRequest()}>
                          Send Request by Email
                      </Button>
                  </Flex>
                )}
                
                {requestToken ==='' && (
                <>
                  <InputField
                    mb='24px'
                    mt='8px'
                    id='recepient'
                    label='Request amount:'
                    placeholder='amount'
                    value={amountPay}
                    onChange={(e) => {

                      if ((selectedNetwork === '' || selectedToken === '') && !isOverlay) {
                        setOverlayText('Select the Network and Token first')
                        handleOverlay(2000);
                        return;
                      }

                      if (/^[0-9.]*$/.test(e.target.value)) {
                        setAmountPay(e.target.value);
                      }
                      setRequestToken('');
                    }}
                  />
                  <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                    {Array.from({ length: 9 }, (_, i) => (
                    <GridItem key={i} colSpan={1}>
                        <Button onClick={() => handleNumberClick(i + 1)} size="lg" w="100%">
                        {i + 1}
                        </Button>
                    </GridItem>
                    ))}
                    <GridItem colSpan={1}>
                    <Button onClick={handleClearClick} size="lg" w="100%">
                        Clear
                    </Button>
                    </GridItem>
                    <GridItem colSpan={1}>
                    <Button onClick={() => handleNumberClick(0)} size="lg" w="100%">
                        0
                    </Button>
                    </GridItem>
                    <GridItem colSpan={1}>
                    <Button onClick={handleSetDecimal} size="lg" w="100%">
                        ,
                    </Button>
                    </GridItem>
                  </Grid>
                </>
                )}

                {requestToken ==='' && (
                    <Button
                      w='100%'
                      fontSize='md'
                      p='6px 12px'
                      bg='linear-gradient(108.54deg, #FF416C 6.56%, #FF4B2B 95.2%)'
                      mt="20px"
                      color='white'
                      textAlign='center'
                      borderRadius='10px'
                      fontWeight='700'
                      onClick={()=>generateRequest()}>
                        Generate request
                    </Button>
                )}

              </Flex>
              
            </Flex>


            <Flex direction='column' w='100%'>

              <InputField
                  mb='24px'
                  mt='8px'
                  id='recepient'
                  label='Name of product:'
                  placeholder='name'
                  value={requestTitle}
                  onChange={(e) => setRequestTitle(e.target.value)}
                />
              <TextField
                  mb='24px'
                  mt='8px'
                  id='recepient'
                  label='Description of product:'
                  placeholder='description'
                  value={requestText}
                  onChange={(e) => setRequestText(e.target.value)}
                />
              <ButtonSelect 
                  mb='24px'
                  mt='8px'
                  label='Select the network'
                  isInvalid={selectedNetwork==='' && startPayment}
                  errorMessage='Network is required'
                  value={selectedNetwork}
                  onChange={(e) => {
                    getAvailableTokens(e);
                    setSelectedNetwork(e);
                    setRequestToken('');
                  }}
                  fixedElements={[]}
                  baseOptions={supportedNetworks}
                />
                <ButtonSelect 
                  mb='24px'
                  mt='8px'
                  label='Select the token'
                  isInvalid={selectedToken==='' && startPayment}
                  errorMessage='Token is required'
                  value={selectedToken}
                  onChange={(e) => {
                    console.log(selectedToken)
                    console.log(e)

                    setSelectedToken(e);

                    const token = availableTokens.find(t => t.symbol === e);
                    const address = token ? token.address : '';

                    setSelectedTokenAddress(address);

                    setRequestToken('');
                  }}
                  fixedElements={[]}
                  baseOptions={availableTokensSymbols}
                />
              
            </Flex>
            
          </Flex>
        </Card>
      </Flex>

      <PinInputModal isOpen={isModalOpen} onClose={closeModal} submitPin={submitPin} recepient={receiverWallet} amount={amount + " " + token}/>

      <OutcomeModal isOpen={isOutcomeModalOpen} onClose={closeOutcomeModal} outcome={payOutcome} outcomeMessage={outcomeMessage}/>

      <Overlay isOpen={isOverlay} text={overlayText}/>
    </>
  );
}
