import React from 'react';
import { Box, Heading, Text, Table, Tbody, Tr, Td } from '@chakra-ui/react';
import DefaultAuth from "../../../layouts/auth/auth";

import illustration from "../../../assets/img/auth.png";
import illustrationMobile from "../../../assets/img/authMobile.png";

const PrivacyPolicy = () => {
  return (
    <>
      <DefaultAuth illustrationBackground={illustration} illustrationBackgroundMobile={illustrationMobile} image={illustration}>
        <Box
            maxW={{ base: "100%", md: "max-content" }}
            w={{ base: "100%", lg: "40%" }}
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}>
               <Heading as="h2" size="lg" mb={6}>PRIVACY POLICY</Heading>
                <Text mb={8}>Modified on 12 December 2023</Text>
                <Text mb={4}>
                    This privacy policy applies between you, the User of this Website and Microclash LTd., the owner and provider of this Website. Microclash LTd. takes the privacy of your information very seriously. This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website.
                </Text>
                <Text mb={4}>
                    This privacy policy should be read alongside, and in addition to, our Terms and Conditions, which can be found at: <a href='https://paypangea.com/terms-of-service'>https://paypangea.com/terms-of-service</a>.
                </Text>
                <Text mb={4}>
                    <b>Please read this privacy policy carefully.</b>
                </Text>
                    
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Definitions and interpretation</sTrong>
                </Heading>
                <Text mb={4}>
                    1. In this privacy policy, the following definitions are used:
                </Text>
                <Table variant="simple">
                    <Tbody>
                        <Tr>
                            <Td>
                                <Text mb={4}>
                                    <sTrong>Data</sTrong>
                                </Text>
                            </Td>
                            <Td>
                                <Text mb={4}>
                                    collectively all information that you submit to Microclash LTd. via the Website. This definition incorporates, where applicable, the definitions provided in
                                    the Data Protection Laws;
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text mb={4}>
                                    <sTrong>Cookies</sTrong>
                                </Text>
                            </Td>
                            <Td>
                                <Text mb={4}>
                                    a small text file placed on your computer by this Website
                                    when you visit certain parts of the Website and/or when you
                                    use certain features of the Website. Details of the cookies
                used by this Website are set out in the clause below (                    <sTrong>Cookies</sTrong>);
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text mb={4}>
                                    <sTrong>Data Protection Laws</sTrong>
                                </Text>
                            </Td>
                            <Td>
                                <Text mb={4}>
                                    any applicable law relating to the processing of personal
                                    Data, including but not limited to the Directive 96/46/EC
                                    (Data Protection Directive) or the GDPR, and any national
                                    implementing laws, regulations and secondary legislation,
                                    for as long as the GDPR is effective in the UK;
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text mb={4}>
                                    <sTrong>GDPR</sTrong>
                                </Text>
                            </Td>
                            <Td>
                                <Text mb={4}>
                                    the General Data Protection Regulation (EU) 2016/679;
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text mb={4}>
                                    <sTrong>Microclash LTd., we </sTrong>
                                    or <sTrong>us</sTrong>
                                </Text>
                            </Td>
                            <Td>
                                <Text mb={4}>
                                    6.20 World Trade Center, 6 Bayside Road, Gibraltar, GX111AA Gibraltar;
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text mb={4}>
                                    <sTrong>UK and EU Cookie Law</sTrong>
                                </Text>
                            </Td>
                            <Td>
                                <Text mb={4}>
                                    the Privacy and ElecTronic Communications (EC Directive)
                                    Regulations 2003 as amended by the Privacy and ElecTronic
                                    Communications (EC Directive) (Amendment) Regulations 2011;
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text mb={4}>
                                    <sTrong>User</sTrong>
                                    or <sTrong>you</sTrong>
                                </Text>
                            </Td>
                            <Td>
                                <Text mb={4}>
                                    any third party that accesses the Website and is not either
                                    (i) employed by Microclash LTd. and acting
                                    in the course of their employment or (ii) engaged as a
                                    consultant or otherwise providing services to BVB Marketing
                                    Consultants LTd. and accessing the Website in connection
                                    with the provision of such services; and
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text mb={4}>
                                    <sTrong>Website</sTrong>
                                </Text>
                            </Td>
                            <Td>
                                <Text mb={4}>
                                    the website that you are currently using,
                                    www.coinclash.games, and any sub-domains of this site unless
                                    expressly excluded by their own terms and conditions.
                                </Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
                <Text mb={4}>
                    2. In this privacy policy, unless the context requires a different
                    interpretation:
                </Text>
                <Text mb={4}>
                    a. the singular includes the plural and vice versa;
                </Text>
                <Text mb={4}>
                    b. references to sub-clauses, clauses, schedules or appendices are to
                    sub-clauses, clauses, schedules or appendices of this privacy policy;
                </Text>
                <Text mb={4}>
                    c. a reference to a person includes firms, companies, government entities,
                    Trusts and partnerships;
                </Text>
                <Text mb={4}>
                    d. "including" is understood to mean "including without limitation";
                </Text>
                <Text mb={4}>
                    e. reference to any statutory provision includes any modification or
                    amendment of it;
                </Text>
                <Text mb={4}>
                    f. the headings and sub-headings do not form part of this privacy policy.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Scope of this privacy policy</sTrong>
                </Heading>
                <Text mb={4}>
                    3. This privacy policy applies only to the actions of Microclash LTd. and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not
                    limited to, any links we may provide to social media websites.
                </Text>
                <Text mb={4}>
                    4. For purposes of the applicable Data Protection Laws, Microclash LTd. is the "data conTroller". This means that Microclash LTd. determines the purposes for which, and the manner in
                    which, your Data is processed.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Data collected</sTrong>
                </Heading>
                <Text mb={4}>
                    5. We may collect the following Data, which includes personal Data, from
                    you:
                </Text>
                <Text mb={4}>
                    a. name;
                </Text>
                <Text mb={4}>
                    b. contact Information such as email addresses and telephone numbers;
                </Text>
                <Text mb={4}>
                    c. IP address (automatically collected);
                </Text>
                <Text mb={4}>
                    d. web browser type and version (automatically collected);
                </Text>
                <Text mb={4}>
                    e. operating system (automatically collected);
                </Text>
                <Text mb={4}>
                    f. a list of URLs starting with a referring site, your activity on this
                    Website, and the site you exit to (automatically collected);
                </Text>
                <Text mb={4}>
                    in each case, in accordance with this privacy policy.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>How we collect Data</sTrong>
                </Heading>
                <Text mb={4}>
                    6. We collect Data in the following ways:
                </Text>
                <Text mb={4}>
                    a. data is given to us by you ; and
                </Text>
                <Text mb={4}>
                    b. data is collected automatically.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Data that is given to us by you</sTrong>
                </Heading>
                <Text mb={4}>
                    7. Microclash LTd. will collect your Data in a number of
                    ways, for example:
                </Text>
                <Text mb={4}>
                    a. when you contact us through the Website, by telephone, post, e-mail or
                    through any other means;
                </Text>
                <Text mb={4}>
                    b. when you register with us and set up an account to receive our
                    products/services;
                </Text>
                <Text mb={4}>
                    c. when you complete surveys that we use for research purposes (although
                    you are not obliged to respond to them);
                </Text>
                <Text mb={4}>
                    d. when you enter a competition or promotion through a social media
                    channel;
                </Text>
                <Text mb={4}>
                    e. when you make payments to us, through this Website or otherwise;
                </Text>
                <Text mb={4}>
                    f. when you elect to receive marketing communications from us;
                </Text>
                <Text mb={4}>
                    g. when you use our services;
                </Text>
                <Text mb={4}>
                    in each case, in accordance with this privacy policy.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Data that is collected automatically</sTrong>
                </Heading>
                <Text mb={4}>
                    8. To the extent that you access the Website, we will collect your Data
                    automatically, for example:
                </Text>
                <Text mb={4}>
                    a. we automatically collect some information about your visit to the
                    Website. This information helps us to make improvements to Website content
                    and navigation, and includes your IP address, the date, times and frequency
                    with which you access the Website and the way you use and interact with its
                    content.
                </Text>
                <Text mb={4}>
                    b. we will collect your Data automatically via cookies, in line with the
                    cookie settings on your browser. For more information about cookies, and
                    how we use them on the Website, see the section below, headed "Cookies".
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Our use of Data</sTrong>
                </Heading>
                <Text mb={4}>
                    9. Any or all of the above Data may be required by us from time to time in
                    order to provide you with the best possible service and experience when
                    using our Website. Specifically, Data may be used by us for the following
                    reasons:
                </Text>
                <Text mb={4}>
                    a. internal record keeping;
                </Text>
                <Text mb={4}>
                    b. improvement of our products / services;
                </Text>
                <Text mb={4}>
                    c. Transmission by email of marketing materials that may be of interest to
                    you;
                </Text>
                <Text mb={4}>
                    in each case, in accordance with this privacy policy.
                </Text>
                <Text mb={4}>
                    10. We may use your Data for the above purposes if we deem it necessary to
                    do so for our legitimate interests. If you are not satisfied with this, you
                    have the right to object in certain circumstances (see the section headed
                    "Your rights" below).
                </Text>
                <Text mb={4}>
                    11. For the delivery of direct marketing to you via e-mail, we'll need your
                    consent, whether via an opt-in or soft-opt-in:
                </Text>
                <Text mb={4}>
                    a. soft opt-in consent is a specific type of consent which applies when you
                    have previously engaged with us (for example, you contact us to ask us for
                    more details about a particular product/service, and we are marketing
                    similar products/services). Under "soft opt-in" consent, we will take your
                    consent as given unless you opt-out.
                </Text>
                <Text mb={4}>
                    b. for other types of e-marketing, we are required to obtain your explicit
                    consent; that is, you need to take positive and affirmative action when
                    consenting by, for example, checking a tick box that we'll provide.
                </Text>
                <Text mb={4}>
                    c. if you are not satisfied about our approach to marketing, you have the
                    right to withdraw consent at any time. To find out how to withdraw your
                    consent, see the section headed "Your rights" below.
                </Text>
                <Text mb={4}>
                    12. When you register with us and set up an account to receive our
                    services, the legal basis for this processing is the performance of a
                    conTract between you and us and/or taking steps, at your request, to enter
                    into such a conTract.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Who we share Data with</sTrong>
                </Heading>
                <Text mb={4}>
                    13. We may share your Data with the following groups of people for the
                    following reasons:
                </Text>
                <Text mb={4}>
                    a. any of our group companies or affiliates - to ensure the proper
                    adminisTration of your website and business;
                </Text>
                <Text mb={4}>
                    b. our employees, agents and/or professional advisors - to obtain advice
                    from professional advisers;
                </Text>
                <Text mb={4}>
                    c. third party service providers who provide services to us which require
                    the processing of personal data - to help third party service providers in
                    receipt of any shared data to perform functions on our behalf to help
                    ensure the website runs smoothly;
                </Text>
                <Text mb={4}>
                    d. third party payment providers who process payments made over the Website
                    - to enable third party payment providers to process user payments and
                    refunds;
                </Text>
                <Text mb={4}>
                    in each case, in accordance with this privacy policy.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Keeping Data secure</sTrong>
                </Heading>
                <Text mb={4}>
                    14. We will use technical and organisational measures to safeguard your
                    Data, for example:
                </Text>
                <Text mb={4}>
                    a. access to your account is conTrolled by data that
                    is unique to you.
                </Text>
                <Text mb={4}>
                    b. we store your Data on secure servers.
                </Text>
                <Text mb={4}>
                    15. We are certified to PCI DSS. This family of standards helps us manage
                    your Data and keep it secure.
                </Text>
                <Text mb={4}>
                    16. Technical and organisational measures include measures to deal with any
                    suspected data breach. If you suspect any misuse or loss or unauthorised
                    access to your Data, please let us know immediately by contacting us via
                    this e-mail address: gdpr@paypangea.com.
                </Text>
                <Text mb={4}>
                    17. If you want detailed information from Get Safe Online on how to protect
                    your information and your computers and devices against fraud, identity
                    theft, viruses and many other online problems, please visit
                    www.getsafeonline.org. Get Safe Online is supported by HM Government and
                    leading businesses.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Data retention</sTrong>
                </Heading>
                <Text mb={4}>
                    18. Unless a longer retention period is required or permitted by law, we
                    will only hold your Data on our systems for the period necessary to fulfil
                    the purposes outlined in this privacy policy or until you request that the
                    Data be deleted.
                </Text>
                <Text mb={4}>
                    19. Even if we delete your Data, it may persist on backup or archival media
                    for legal, tax or regulatory purposes.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Your rights</sTrong>
                </Heading>
                <Text mb={4}>
                    20. You have the following rights in relation to your Data:
                </Text>
                <Text mb={4}>
                    a. <sTrong>Right to access</sTrong> - the right to request (i) copies of
                    the information we hold about you at any time, or (ii) that we modify,
                    update or delete such information. If we provide you with access to the
                    information we hold about you, we will not charge you for this, unless your
                    request is "manifestly unfounded or excessive." Where we are legally
                    permitted to do so, we may refuse your request. If we refuse your request,
                    we will tell you the reasons why.
                </Text>
                <Text mb={4}>
                    b. <sTrong>Right to correct</sTrong> - the right to have your Data
                    rectified if it is inaccurate or incomplete.
                </Text>
                <Text mb={4}>
                    c. <sTrong>Right to erase</sTrong> - the right to request that we delete or
                    remove your Data from our systems.
                </Text>
                <Text mb={4}>
                    d. <sTrong>Right to resTrict our use of your Data</sTrong> - the right to
                    "block" us from using your Data or limit the way in which we can use it.
                </Text>
                <Text mb={4}>
                    e. <sTrong>Right to data portability</sTrong> - the right to request that
                    we move, copy or Transfer your Data.
                </Text>
                <Text mb={4}>
                    f. <sTrong>Right to object</sTrong> - the right to object to our use of
                    your Data including where we use it for our legitimate interests.
                </Text>
                <Text mb={4}>
                    21. To make enquiries, exercise any of your rights set out above, or
                    withdraw your consent to the processing of your Data (where consent is our
                    legal basis for processing your Data), please contact us via this e-mail
                    address: gdpr@paypangea.com.
                </Text>
                <Text mb={4}>
                    22. If you are not satisfied with the way a complaint you make in relation
                    to your Data is handled by us, you may be able to refer your complaint to
                    the relevant data protection authority. For the UK, this is the Information
                    Commissioner's Office (ICO). The ICO's contact details can be found on
                    their website at https://ico.org.uk/.
                </Text>
                <Text mb={4}>
                    23. It is important that the Data we hold about you is accurate and
                    current. Please keep us informed if your Data changes during the period for
                    which we hold it.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Transfers outside the European Economic Area</sTrong>
                </Heading>
                <Text mb={4}>
                    24. Data which we collect from you may be stored and processed in and
                    Transferred to counTries outside of the European Economic Area (EEA). For
                    example, this could occur if our servers are located in a counTry outside
                    the EEA or one of our service providers is situated in a counTry outside
                    the EEA.
                </Text>
                <Text mb={4}>
                    25. We will only Transfer Data outside the EEA where it is compliant with
                    data protection legislation and the means of Transfer provides adequate
                    safeguards in relation to your data, eg by way of data Transfer agreement,
                    incorporating the current standard conTractual clauses adopted by the
                    European Commission, or by signing up to the EU-US Privacy Shield
                    Framework, in the event that the organisation in receipt of the Data is
                    based in the United States of America.
                </Text>
                <Text mb={4}>
                    26. To ensure that your Data receives an adequate level of protection, we
                    have put in place appropriate safeguards and procedures with the third
                    parties we share your Data with. This ensures your Data is Treated by those
                    third parties in a way that is consistent with the Data Protection Laws.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Links to other websites</sTrong>
                </Heading>
                <Text mb={4}>
                    27. This Website may, from time to time, provide links to other websites.
                    We have no conTrol over such websites and are not responsible for the
                    content of these websites. This privacy policy does not extend to your use
                    of such websites. You are advised to read the privacy policy or statement
                    of other websites prior to using them.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Changes of business ownership and conTrol</sTrong>
                </Heading>
                <Text mb={4}>
                    28. Microclash LTd. may, from time to time, expand or reduce
                    our business and this may involve the sale and/or the Transfer of conTrol
                    of all or part of Microclash LTd.. Data provided by Users
                    will, where it is relevant to any part of our business so Transferred, be
                    Transferred along with that part and the new owner or newly conTrolling
                    party will, under the terms of this privacy policy, be permitted to use the
                    Data for the purposes for which it was originally supplied to us.
                </Text>
                <Text mb={4}>
                    29. We may also disclose Data to a prospective purchaser of our business or
                    any part of it.
                </Text>
                <Text mb={4}>
                    30. In the above instances, we will take steps with the aim of ensuring
                    your privacy is protected.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Cookies</sTrong>
                </Heading>
                <Text mb={4}>
                    31. This Website may place and access certain Cookies on your computer. Microclash LTd. uses Cookies to improve your experience of using
                    the Website and to improve our range of services. Microclash
                    LTd. has carefully chosen these Cookies and has taken steps to ensure that
                    your privacy is protected and respected at all times.
                </Text>
                <Text mb={4}>
                    32. All Cookies used by this Website are used in accordance with current UK
                    and EU Cookie Law.
                </Text>
                <Text mb={4}>
                    33. Before the Website places Cookies on your computer, you will be
                    presented with a message bar requesting your consent to set those Cookies.
                    By giving your consent to the placing of Cookies, you are enabling Microclash LTd. to provide a better experience and service to
                    you. You may, if you wish, deny consent to the placing of Cookies; however
                    certain features of the Website may not function fully or as intended.
                </Text>
                <Text mb={4}>
                    34. This Website may place the following Cookies:
                </Text>
                <Table variant="simple">
                    <Tbody>
                        <Tr>
                            <Td width="30%">
                                <Text>
                                    <sTrong>Type of Cookie</sTrong>
                                </Text>
                            </Td>
                            <Td width="70%">
                                <Text>
                                    <sTrong>Purpose</sTrong>
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text>
                                    Analytical/performance cookies
                                </Text>
                            </Td>
                            <Td>
                                <Text>
                                    They allow us to recognise and count the number of visitors
                                    and to see how visitors move around our website when they
                                    are using it. This helps us to improve the way our website
                                    works, for example, by ensuring that users are finding what
                                    they are looking for easily.
                                </Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
                <Text mb={4}>
                    35. You can find a list of Cookies that we use in the Cookies Schedule.
                </Text>
                <Text mb={4}>
                    36. You can choose to enable or disable Cookies in your internet browser.
                    By default, most internet browsers accept Cookies but this can be changed.
                    For further details, please consult the help menu in your internet browser.
                </Text>
                <Text mb={4}>
                    37. You can choose to delete Cookies at any time; however you may lose any
                    information that enables you to access the Website more quickly and
                    efficiently including, but not limited to, personalisation settings.
                </Text>
                <Text mb={4}>
                    38. It is recommended that you ensure that your internet browser is
                    up-to-date and that you consult the help and guidance provided by the
                    developer of your internet browser if you are unsure about adjusting your
                    privacy settings.
                </Text>
                <Text mb={4}>
                    39. For more information generally on cookies, including how to disable
                    them, please refer to aboutcookies.org. You will also find details on how
                    to delete cookies from your computer.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>General</sTrong>
                </Heading>
                <Text mb={4}>
                    40. You may not Transfer any of your rights under this privacy policy to
                    any other person. We may Transfer our rights under this privacy policy
                    where we reasonably believe your rights will not be affected.
                </Text>
                <Text mb={4}>
                    41. If any court or competent authority finds that any provision of this
                    privacy policy (or part of any provision) is invalid, illegal or
                    unenforceable, that provision or part-provision will, to the extent
                    required, be deemed to be deleted, and the validity and enforceability of
                    the other provisions of this privacy policy will not be affected.
                </Text>
                <Text mb={4}>
                    42. Unless otherwise agreed, no delay, act or omission by a party in
                    exercising any right or remedy will be deemed a waiver of that, or any
                    other, right or remedy.
                </Text>
                <Text mb={4}>
                    43. This Agreement will be governed by and interpreted according to the law
                    of England and Wales. All disputes arising under the Agreement will be
                    subject to the exclusive jurisdiction of the English and Welsh courts.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Changes to this privacy policy</sTrong>
                </Heading>
                <Text mb={4}>
                    44. Microclash LTd. reserves the right to change this
                    privacy policy as we may deem necessary from time to time or as may be
                    required by law. Any changes will be immediately posted on the Website and
                    you are deemed to have accepted the terms of the privacy policy on your
                    first use of the Website following the alterations. You may contact PayPangea Corp by email at gdpr@paypangea.com.
                </Text>
                <Heading as="h2" size="lg" mb={6}>
                    <sTrong>Atribution</sTrong>
                </Heading>
                <Text mb={4}>
                45. This privacy policy was created using a document from    <a href="https://www.rocketlawyer.com/gb/en/">Rocket Lawyer</a>
                    (https://www.rocketlawyer.com/gb/en).
                </Text>
                <Text mb={4}>
                    <sTrong>Cookies</sTrong>
                </Text>
                <Text mb={4}>
                    Below is a list of the cookies that we use. We have Tried to ensure this is
                    complete and up to date, but if you think that we have missed a cookie or
                    there is any discrepancy, please let us know.
                </Text>
                <Text mb={4}>
                    Analytical/performance
                </Text>
                <Text mb={4}>
                    We use the following analytical/performance cookies:
                </Text>
                <Table variant="simple">
                    <Tbody>
                        <Tr>
                            <Td width="25%">
                                <Text mb={4}>
                                    <sTrong>Description of Cookie</sTrong>
                                    <sTrong></sTrong>
                                </Text>
                            </Td>
                            <Td width="25%">
                                <Text mb={4}>
                                    <sTrong>Purpose</sTrong>
                                    <sTrong></sTrong>
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Text mb={4}>
                                    We use this cookie to help us analyse how users use the
                                    website
                                </Text>
                            </Td>
                            <Td>
                                <Text mb={4}>
                                    We use this cookie to help us analyse how users use the
                                    website
                                </Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>

              <Text mb={4} height="50px"></Text>
            </Box>
      </DefaultAuth>
    </>
    
  );
}

export default PrivacyPolicy;
