// Chakra Imports
import { Box, Breadcrumb, BreadcrumbItem, Image, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AdminNavbarLinks from '../../components/navbar/NavbarLinksAdmin';

import { PayPangeaLogo } from "../../components/icons/Icons";

import payPangeaLogoBlack from "../../assets/img/PayPangeaLogoBlack.png";
import payPangeaLogoWhite from "../../assets/img/PayPangeaLogoWhite.png";

export default function AdminNavbar(props) {
	const [ scrolled, setScrolled ] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});

	const { secondary, message, brandText } = props;

	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let mainText = useColorModeValue('navy.700', 'white');
	let secondaryText = useColorModeValue('gray.700', 'white');
	let navbarPosition = 'fixed';
	let navbarFilter = 'none';
	let navbarBackdrop = 'blur(20px)';
	let navbarShadow = 'none';
	let navbarBg = useColorModeValue('whiteAlpha.500', 'rgba(11,20,55,0.5)');
	let navbarBorder = 'transparent';
	let secondaryMargin = '0px';
	let paddingX = '15px';
	let gap = '0px';
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	let payPangeaLogo = useColorModeValue(payPangeaLogoBlack, payPangeaLogoWhite);

	return (
		<>
			{/* <Box
				zIndex='2'
				position={navbarPosition}
				boxShadow={navbarShadow}
				bg={'grey'}
				borderColor={navbarBorder}
				filter={navbarFilter}
				backdropFilter={navbarBackdrop}
				backgroundPosition='center'
				backgroundSize='cover'
				borderRadius='16px'
				borderWidth='1.5px'
				borderStyle='solid'
				transitionDelay='0s, 0s, 0s, 0s'
				transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
				transition-property='box-shadow, background-color, filter, border'
				transitionTimingFunction='linear, linear, linear, linear'
				alignItems={{ xl: 'center' }}
				display={{base: "flex", md:'none'}}
				minH='75px'
				justifyContent={{ xl: 'center' }}
				lineHeight='25.6px'
				mx='auto'
				mt={secondaryMargin}
				pb='8px'
				right={{ base: '20px', md: '30px' }}
				px={{
					sm: paddingX,
					md: '10px'
				}}
				ps={{
					xl: '12px'
				}}
				pt='16px'
				top={{ base: '-16px', md: '16px' }}
				w={{
					base: 'calc(100vw - 6%)',
					md: 'calc(100vw - 8%)',
				}}>
					Header

			</Box> */}
			<Box
				zIndex='2'
				position={navbarPosition}
				boxShadow={navbarShadow}
				bg={navbarBg}
				borderColor={navbarBorder}
				filter={navbarFilter}
				backdropFilter={navbarBackdrop}
				backgroundPosition='center'
				backgroundSize='cover'
				borderRadius='16px'
				borderWidth='1.5px'
				borderStyle='solid'
				transitionDelay='0s, 0s, 0s, 0s'
				transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
				transition-property='box-shadow, background-color, filter, border'
				transitionTimingFunction='linear, linear, linear, linear'
				alignItems={{ xl: 'center' }}
				display={secondary ? 'block' : 'flex'}
				// display={{base: "none", md:secondary ? 'block' : 'flex'}}
				minH='75px'
				justifyContent={{ xl: 'center' }}
				lineHeight='25.6px'
				mx='auto'
				mt={secondaryMargin}
				pb='8px'
				right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
				px={{
					sm: paddingX,
					md: '10px'
				}}
				ps={{
					xl: '12px'
				}}
				pt='8px'
				top={{ base: '-8px', md: '16px', xl: '18px' }}
				w={{
					base: 'calc(100vw - 6%)',
					md: 'calc(100vw - 8%)',
					lg: 'calc(100vw - 6%)',
					xl: 'calc(100vw - 350px)',
					'2xl': 'calc(100vw - 365px)'
				}}>
				<Flex
					w='100%'
					flexDirection='row'
					alignItems='center'
					mb={gap}>
					<Box 
						display='block'
						h={'24px'}
						mb={{ sm: '0px', md: '0px' }}>
						<Link
							color={mainText}
							href='/admin/home'
							bg='inherit'
							borderRadius='inherit'
							fontSize='24px'>
							<Image 
							  position='absolute'
							  src={payPangeaLogo} 
							  h='24px'
							  alt="PayPangea Logo" />
						</Link>
					</Box>
					<Box ms='auto' >
						<AdminNavbarLinks
							onOpen={props.onOpen}
							username={props.username}
							secondary={props.secondary}
							fixed={props.fixed}
							scrolled={scrolled}
						/>
					</Box>
				</Flex>
				{secondary ? <Text color='white'>{message}</Text> : null}
			</Box>
		</>
	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	username: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};
