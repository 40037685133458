
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import Connected from "../../../views/dashboard/main/account/settings/components/Connected";
import Delete from "../../../views/dashboard/main/account/settings/components/Delete";
import ApiInformation from "../../../views/dashboard/main/account/settings/components/ApiInformation";
import Newsletter from "../../../views/dashboard/main/account/settings/components/Newsletter";
import Socials from "../../../views/dashboard/main/account/settings/components/Socials";
import React from "react";

export default function Settings() {
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1, lg: 2 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {/* Column Left */}
        <Flex direction='column'>
          <ApiInformation />
          {/* <Socials /> */}
          {/* <Delete /> */}
        </Flex>
        {/* Column Right */}
        <Flex direction='column'>
          {/* <Connected mb='20px' />
          <Newsletter mb='20px' /> */}
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
