import React from 'react';

import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { TbCertificate } from "react-icons/tb";

// Admin Imports
import Dashboard from './views/dashboard/main/main';
import Marketplace from './views/dashboard/marketplace/main';
import TransferPage from './views/dashboard/transactions/main';
import TopUpPage from './views/dashboard/topup/main';
import RequestPage from './views/dashboard/request/main';
import PayPage from './views/dashboard/payPage/main';
import AccountPage from './views/dashboard/account/main';
import Memories from './views/dashboard/memories/main';

const routes = [  
	{
		name: 'Main',
		layout: '/admin',
		path: '/home',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:true,
		component: <Dashboard/>
	},
	// {
	// 	name: 'Partners Marketplace',
	// 	layout: '/admin',
	// 	path: '/marketplace',
	// 	icon: <Icon as={MdOutlineProductionQuantityLimits} width='20px' height='20px' color='inherit' />,
	// 	collapse: false,
	// 	show:true,
	// 	component: <Marketplace/>
	// },
	{
		name: 'Certificates and memories',
		layout: '/admin',
		path: '/certificates',
		icon: <Icon as={TbCertificate} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:true,
		component: <Memories/>
	},
	{
		name: 'Transfer',
		layout: '/admin',
		path: '/transfer',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:false,
		component: <TransferPage/>
	},
	{
		name: 'Top up',
		layout: '/admin',
		path: '/top-up',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:false,
		component: <TopUpPage/>
	},
	{
		name: 'Request payment',
		layout: '/admin',
		path: '/request-payment',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:false,
		component: <RequestPage/>
	},
	{
		name: 'Crypto Pay',
		layout: '/admin',
		path: '/pay',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:false,
		component: <PayPage/>
	},
	{
		name: 'Account',
		layout: '/admin',
		path: '/account',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:false,
		component: <AccountPage/>
	},
];

export default routes;
