

import { useState, useEffect } from "react";
// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
// Custom components
import { VSeparator, HSeparator } from "../../../components/separator/Separator";
import { ApiHeader } from '../../../_helpers/ApiHeader';

import YourCard from "../../../views/dashboard/main/account/settings/components/YourCard";
import Balance from "../../../views/dashboard/main/account/settings/components/Balance";
import MostVisitedTable from "../../../views/dashboard/main/account/settings/components/MostVisitedTable";
import Overlay from '../../../views/modals/overlay/overlay';

import { tableColumnsMostVisited } from "../../../views/dashboard/main/account/variables/tableColumnsMostVisited";

export default function Default() {
  // Chakra Color Mode
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const [balance, setBalance] = useState('')
  const [wallet, setWallet] = useState('')
  const [tokenBalances, setTokenBalances] = useState([])
  const [transactions, setTransactions] = useState([])
  const [cardnr, setCardnr] = useState('')
  const [ccv, setCcv] = useState('')

  const [chain, setChain] = useState('mainnet')
  const [isLoading, setIsLoading] = useState(true);

  const [isOverlay, setIsOverlay] = useState(false);

  const handleOverlay = (timeWait) => {
    setIsOverlay(true);

    setTimeout(() => {
      // After 1 second (1000 milliseconds), update the state to show content
      setIsOverlay(false);
    }, timeWait);
  };

  useEffect(()=>{
    if (chain==='pangea') {
      handleOverlay(1000);

      setBalance({balance: "0", symbol: ""});
      setTokenBalances([]);
      setTransactions([]);
    } else {
      getTransactionsData();
    }
  },[chain])

  const getTransactionsData = () => {

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL+'user/transactions', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : chain
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {

      setIsLoading(false)
      console.log(data)

      if (data.status === 1) {
        setBalance(data.userData.balance);
        setTokenBalances(data.userData.balances);
        console.log(data.userData.transactions)
        setTransactions(data.userData.transactions);
        setWallet(data.userData.address); 
        setCardnr(data.userData.card); 
        setCcv(data.userData.ccv); 
      } else {

        setBalance({balance: "0", symbol: ""});
        setTokenBalances([]);
        setTransactions([]);
      }
      
    })
    .catch(err => {
      setIsLoading(false)
      setBalance({balance: "0", symbol: ""});
      setTokenBalances([]);
      setTransactions([]);
      console.log("fetch error: " + err);
    });
  }

  return (
    <Flex
      direction={{ base: "column", xl: "row" }}
      pt={{ base: "0px", md: "80px", xl: "80px" }}>

      <YourCard
        minW={{ base: "100%", xl: "400px" }}
        maxW={{ base: "100%", xl: "400px" }}
        maxH={{ base: "100%", xl: "1170px", "2xl": "100%" }}
        wallet={wallet}
        cardnr={cardnr}
        ccv={ccv}
      />
      <VSeparator
        mx='20px'
        bg={paleGray}
        display={{ base: "none", xl: "flex" }}
      />
      <HSeparator
        my='20px'
        bg={paleGray}
        display={{ base: "flex", xl: "none" }}
      />
      <Flex direction='column' width='stretch' gap='20px'>
        <Balance 
          balance={balance}
          tokenbalances={tokenBalances}
          chain={chain}
          setChain={setChain}
          isLoading={isLoading}
        />
        <MostVisitedTable
          tableData={transactions}
          columnsData={tableColumnsMostVisited}
          isLoading={isLoading}
        />
      </Flex>

      <Overlay isOpen={isOverlay} text={'Coming soon ...'}/>
    </Flex>
  );
}
