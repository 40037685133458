import {useState, useEffect} from "react";
import { useNavigate } from 'react-router';
import {QrScanner} from '@yudiel/react-qr-scanner';

// Chakra imports
import { 
  FormLabel,
  Button,
  Input, 
  Flex, 
  Text, 
  useToast,
  useBreakpointValue,
  Icon,
  useColorModeValue } from "@chakra-ui/react";

import { FcNfcSign } from "react-icons/fc";

// Custom components

import PinInputModal from '../../../../views/modals/pin/pin';
import OutcomeModal from '../../../../views/modals/outcome/outcome';
import Overlay from '../../../../views/modals/overlay/overlay';
import Card from "../../../../components/card/Card.js";
import { VSeparator, HSeparator } from "../../../../components/separator/Separator";
import SelectField from "../../../../components/fields/SelectField";
import { ApiHeader } from '../../../../_helpers/ApiHeader';

import { FaCheckCircle } from 'react-icons/fa';

// Assets

export default function Content(props) {
  const { wallet, ...rest } = props;

  const VerifiedIcon = () => {
    if (isUserVerified) {
      return (
        <Icon as={FaCheckCircle} boxSize={4} color="blue.400" />
      );
    }

    return (<></>);
  };

  const toast = useToast();
  const navigate = useNavigate();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgCard = useColorModeValue("white", "navy.700");
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const isMobile = useBreakpointValue({ base: true, md: false });


  const currentPageURL = window.location.search;

  const [receiverWallet, setReceiverWallet] = useState('');
  const [network, setNetwork] = useState('');
  const [amount, setAmount] = useState('');
  const [token, setToken] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [transactionFee, setTransactionFee] = useState('');
  const [walletUsername, setWalletUsername] = useState('');
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const walletDisplayText = useBreakpointValue({
    base: `${receiverWallet.substr(0,8)}...${receiverWallet.slice(-6)}`,
    md: receiverWallet,
  });

  const [startPayment, setStartPayment] = useState(false);

  const [outcomeMessage, setOutcomeMessage] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payTkn, setPayTkn] = useState('');
  const [payOutcome, setPayOutcome] = useState('');
  const [isOverlay, setIsOverlay] = useState(false);

  const [isOutcomeModalOpen, setIsOutcomeModalOpen] = useState(false);

  const [stage, setStage] = useState(0);

  const [isQRScanning, setIsQRScanning] = useState(false);
  const [isNFCScanning, setIsNFCScanning] = useState(false);


  const [scanned, setIsScanned] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openOutcomeModal = () => {
    setIsOutcomeModalOpen(true);
  };

  const closeOutcomeModal = () => {
    setIsOutcomeModalOpen(false);

    navigate(`/admin/home`);
  };

  const handleScan = (data) => {
    if (data && !scanned) {
      const url = new URL(data);
      const tknParam = url.searchParams.get('tkn');
      console.log(tknParam)
      if (tknParam) {
        setIsScanned(true);
        getTransactionData(tknParam);
      } else {
        // Handle error when 'tkn' param is missing

        toast({
          title: 'Error.',
          description: "Invalid QR Code",
          status: 'error',
        })
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  
  const getTransactionData = (requestTkn) => {
    console.log(wallet)
    setIsOverlay(true);

    fetch(process.env.REACT_APP_API_URL+'pay/pay-by-request', {
      method: 'POST',
      body: JSON.stringify({
        "tkn" : requestTkn
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsOverlay(false);
      console.log(data)

      if (data.status === 1) {
        console.log(data.payData.receiver)
        setReceiverWallet(data.payData.receiver)
        setNetwork(data.payData.chain)
        setAmount(data.payData.amount)
        setToken(data.payData.token)
        setTokenAddress(data.payData.tokenAddress)
        setIsUserVerified(data.payData.verified)
        setWalletUsername(data.payData.username)
        setTitle(data.payData.title)
        setText(data.payData.text)
        getTransactionFee();
        setStage(1);
        setIsScanned(false);
      }
      
    })
    .catch(err => {
      setIsOverlay(false);
        console.log("fetch error: " + err);
    });
  }

  const getTransactionFee = (token_address) => {

    console.log(`getTransactionFee ${token_address}`)
 
    if (!token_address) {
      token_address = '0x00'
    }

    fetch(process.env.REACT_APP_API_URL+'pay/estimate-gas', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : 'mainnet',
        "amount": "0",
        "token": token_address
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        setTransactionFee(data.estimatedGas)
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }


  const handleSubmit = () => {
    payToCryptoAddress()
  };


  const payToCryptoAddress = () => {
    setIsOverlay(true);

    fetch(process.env.REACT_APP_API_URL+'pay/send-address', {
      method: 'POST',
      body: JSON.stringify({
        "address": receiverWallet,
        "amount": amount.toString(),
        "token": tokenAddress,
        "chain" : 'mainnet',
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      setIsOverlay(false);
      if (data.status === 1) {
        setPayTkn(data.paymentData.tkn)

        openModal();

      } else if (data.status === 2) {
        setPayOutcome('FAILURE');
        setOutcomeMessage(`${data.message}.\nPlease top up your account.`)

        openOutcomeModal(true);
      }
      
    })
    .catch(err => {
      setIsOverlay(false);
        console.log("fetch error: " + err);
    });
  }

  const submitPin = (submitedPin) => {
    console.log(`submitedPin ${submitedPin}`)
    setIsModalOpen(false);
    confirmPay(submitedPin)
  };


  const confirmPay = (pin) => {
    setIsOverlay(true);

    fetch(process.env.REACT_APP_API_URL+'pay/verify-payment', {
      method: 'POST',
      body: JSON.stringify({
        "tkn": payTkn,
        "passkey": pin,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsOverlay(false);
      console.log(data)

      if (data.status === 1) {
        setPayOutcome('SUCCESS');
      } else {
        setPayOutcome('FAILURE');
      }
      openOutcomeModal(true);
    })
    .catch(err => {
        console.log("fetch error: " + err);

      setIsOverlay(false);
        toast({
          title: 'Error.',
          description: "Something went wrong, please try again",
          status: 'error',
        })
    });
  }

  const showScanPart = () => {
    return (    
      <Flex 
      direction='column' 
      me='auto' 
      width="100%"
      mb={{base:"30px", md:"0px"}}>
        {(!isQRScanning && !isNFCScanning) && (
          <Flex
            direction='column' 
            gap='20px'
            p='20px'>
            <Button onClick={() => setIsQRScanning(true)} 
              variant='brand' 
              mt='20px'
              size="lg" 
              w="100%">
              Scan QR code
            </Button>
            <Button onClick={() => setIsNFCScanning(true)} 
              variant='brand' 
              mt='20px'
              size="lg" 
              w="100%">
              NFC Scan
            </Button>
          </Flex>
        )}

        {isQRScanning && (
          <div style={{ marginBottom: '30px'}}>
            <Text color={textColor} fontSize='xl' fontWeight='bold' mb='15px'>
              Scan the QR code to pay
            </Text>
            <QrScanner
                onDecode={handleScan}
                onError={handleError}
                mb='30px'
            />

            <Button onClick={() => setIsQRScanning(false)} 
              variant='brand' 
              mt='20px'
              size="lg" 
              w="100%">
              Stop scanning
            </Button>
          </div>
        )}

        {isNFCScanning && (
          <div style={{ marginBottom: '30px'}}>
            <Text color={textColor} fontSize='xl' fontWeight='bold' mb='15px'>
              Scan the NFC tag
            </Text> 
            
            <div style={{ width: '50%', margin: '10% auto' }}>
              <Icon as={FcNfcSign} color={textColor} width="100%" height='150px' />
            </div>

            <Button onClick={() => setIsNFCScanning(false)} 
              variant='brand' 
              mt='20px'
              size="lg" 
              w="100%">
              Stop scanning
            </Button>
          </div>
        )}

        <Flex align='center' mb='25px'>
          <HSeparator />
          <Text color={textColor} mx='14px'>
            or
          </Text>
          <HSeparator />
        </Flex>

        <Text color={textColor} fontSize='xl' fontWeight='bold' mb='15px'>
            Input the request token
        </Text>

        <Input
          type='text'
          fontWeight='500'
          variant='main'
          placeholder={'Request token'}
          _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
          h='44px'
          maxh='44px'
          onChange={(e) => {
            setPayTkn(e.target.value);
          }}
        />
        <Button onClick={() => {
          if (payTkn!=='') {
            getTransactionData(payTkn);
          }
        }} 
          variant='brand' 
          mt='20px'
          size="lg" 
          w="100%">
          Check request
        </Button>

      </Flex>
    )
  }

  const showpayPart = () => {
    return (    
      <Flex 
      direction='column' 
      me='auto' 
      width="100%"
      mb={{base:"30px", md:"0px"}}>
        <Flex 
        direction='column' 
        me='auto' 
        width={"100%"}
        mb={{base:"30px", md:"0px"}}>

        <Text
            color={textColor}
            fontWeight='bold'
            fontSize='24px'
            mb={'20px'}
            textAlign={'left'}>
            {title}
          </Text>   
          <Text
            color={textColor}
            fontWeight='bold'
            fontSize='sm'
            mb={'20px'}
            textAlign={'left'}>
            {text}
          </Text>   
          <Text
            color={textColor}
            fontWeight='bold'
            fontSize='38px'
            textAlign={'right'}>
            {amount} {token}
          </Text>   
          <Text
            color={textColor}
            fontWeight='bold'
            fontSize='sm'
            textAlign={'right'}>
            {'Transaction fee'}
          </Text>   
          <Text
            color={textColor}
            fontWeight='400'
            fontSize='sm'
            textAlign={'right'}>
            {transactionFee}
          </Text> 
          <Text
            color={textColor}
            fontWeight='bold'
            fontSize='sm'
            textAlign={'right'}>
            {'Network'}
          </Text>   
          <Text
            color={textColor}
            fontWeight='400'
            fontSize='sm'
            textAlign={'right'}>
            {network}
          </Text>   
          <Text
            color={textColor}
            mt={"20px"}
            fontWeight='bold'
            fontSize='sm'
            textAlign={'left'}>
            {'Payable to:'}
          </Text>   
          <Text
            color={textColor}
            fontWeight='400'
            fontSize='sm'
            textAlign={'left'}>
            {walletDisplayText} <VerifiedIcon />
          </Text>
          <Text
            color={textColor}
            fontWeight='bold'
            fontSize='sm'
            mt={"8px"}
            mb={"20px"}
            textAlign={'left'}>
            {walletUsername}
          </Text>
        </Flex>
        <Button onClick={handleSubmit} 
            variant='brand' 
            size="lg" 
            mt="20px"
            w="100%">
            Pay
        </Button>

      </Flex>
    )
  }

  return (
    <>
      <Flex direction='column' p={{base:"10px", md:"60px"}}>
        <Card
          backgroundRepeat='no-repeat'
          bg={bgCard}
          p='30px'
          mb='30px'
          mt='-100px'>     
          {stage === 0 && showScanPart()}
          {stage === 1 && showpayPart()}
        </Card>
      </Flex>

      <PinInputModal isOpen={isModalOpen} onClose={closeModal} submitPin={submitPin} recepient={receiverWallet} amount={amount + " " + token}/>

      <OutcomeModal isOpen={isOutcomeModalOpen} onClose={closeOutcomeModal} outcome={payOutcome} message={outcomeMessage}/>

      <Overlay isOpen={isOverlay} text={'Processing ...'}/>
    </>
  );
}
