import React from 'react';
import {
  chakra,
  Container,
  Stack,
  HStack,
  Text,
  useColorModeValue,
  Button,
  Image,
  Skeleton,
  Box,
  Link,
  Icon
} from '@chakra-ui/react';
import { GoChevronRight } from 'react-icons/go';
import { MdBolt } from 'react-icons/md';
import { useNavigate } from 'react-router';

import bgHand from "../../../../assets/img/hand.png";

const HeroSection = () => {
  const bgColor = useColorModeValue('gray.300', 'gray.700');
  const buttonTextColor = useColorModeValue('white', 'gray.800');

  const navigate = useNavigate()
  return (
    <Box px={4}>
    <Container maxW="6xl" px={{ base: 6, md: 3 }} py={24}>
      <Stack direction={{ base: 'column', md: 'row' }} justifyContent="center">
        <Stack direction="column" spacing={6} justifyContent="center" maxW="480px">
          <chakra.h1 fontSize="5xl" lineHeight={1} fontWeight="bold" textAlign="left">
            Crypto wallet <br />
            <chakra.span>for non-crypto users</chakra.span>
          </chakra.h1>
          <Text
            fontSize="1.2rem"
            textAlign="left"
            lineHeight="1.375"
            fontWeight="400"
            color="gray.300"
          >
            Blockchain wallet made for everyone
          </Text>
          <HStack
            spacing={{ base: 0, sm: 2 }}
            mb={{ base: '3rem !important', sm: 0 }}
            flexWrap="wrap"
          >

            <Button 
              onClick={() => {
                navigate('/auth/login')
              }} 
              variant='brand' 
              mt='20px'
              mb='25px'
              size="lg" >
                Login
              </Button>
          </HStack>
        </Stack>
        <Box ml={{ base: 0, md: 5 }} pos="relative">
          <DottedBox />
          <Image
            w="100%"
            h="100%"
            minW={{ base: 'auto', md: '30rem' }}
            objectFit="cover"
            src={bgHand}
            rounded="md"
            fallback={<Skeleton />}
          />
        </Box>
      </Stack>
    </Container>
    </Box>
  );
};

function DottedBox() {
  const colorValue = useColorModeValue('rgba(55,65,81, 0.1)', 'rgba(55,65,81, 0.7)');
  return (
    <Box position="absolute" left="-45px" top="-30px" height="full" maxW="700px" zIndex={-1}>
           <svg
        color={colorValue}
        width="350"
        height="420"
        fill="none"
      >
        <defs>
          <pattern
            id="dots"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#dots)"></rect>
      </svg>
    </Box>
  );
}

export default HeroSection;
