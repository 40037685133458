

import {useState, useEffect } from "react";
// Chakra imports
import { Flex } from "@chakra-ui/react";

// Custom components
import Card from "../../../components/card/Card";
import Content from "../../../views/request/nftclaim/components/Content";

export default function PayPageSDK() {
  // Chakra Color Mode


  return (
    <Card mt={{ base: "0px", md: "20px", xl: "20px" }} p={{base: "0px", md:"20px 20px 30px 20px"}}maxW='540px' mx='auto'>
      <Flex direction='column' width='stretch'>
        <Content/>
      </Flex>
    </Card>
  );
}
