import React from "react";

// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";

export default function TokenBalance(props) {
  const { balance, name, symbol, decimals, ...rest } = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const formattedBalance = (balance / 10 ** decimals).toLocaleString('en-US', {
    minimumFractionDigits: 6,
  });
  
  return (
    <Flex direction='row' align='start' me='auto' mb='20px' w='100%' maxWidth='420px'>
        <Text color={textColor} fontSize='md' me='6px' fontWeight='700'>
            {name.length < 40 ? name : name.slice(0, 35) + '...'}
        </Text>
        <Text ms='auto' color={textColor} fontSize='md' me='6px' fontWeight='700'>
            {formattedBalance} {symbol.length < 6 ? <span>{symbol}</span> : ''}
        </Text>
    </Flex>
  );
}
