import { JWTToken } from "../_helpers/Auth";

const ApiHeader = (type) => {

    const jwt = JWTToken();

    if (type === 'auth') {

        if (jwt && jwt !== '') {
            return { 
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            };
        } else {
            console.log("no autthData");
            return { 'Content-Type': 'application/json' };
        }
    } else {
        return {
            'Content-Type': 'application/json'
        };
    }
}

export {ApiHeader};