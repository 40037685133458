import React, { useState } from "react";

// Chakra imports
import {
  Flex,
  Box,
  Button,
  IconButton,
  Icon,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router';

// Custom components
import Card from "../../../../../../components/card/Card.js";
import PayPangeaCard from "../../../../../../components/card/PayPangeaCard";
import Transaction from "../../../../../../components/dataDisplay/Transaction";

// Assets
import {
  MdOutlineShoppingBasket,
  MdAddCircle,
  MdOutlineDirectionsBus,
  MdOutlineSubscriptions,
  MdLocalBar,
  MdOutlineWeekend,
  MdCached,
  MdAdd,
  MdAttachMoney,
  MdMoreHoriz,
} from "react-icons/md";
import { RiNetflixFill } from "react-icons/ri";

export default function YourCard(props) {
  const { wallet, cardnr, ccv, ...rest } = props;

  const navigate = useNavigate();

  let [tabState, setTabState] = useState("card1");

  // Chakra Color Mode
  const iconColor = useColorModeValue("brand.500", "white");
  const greenIcon = useColorModeValue("green.500", "white");
  const redIcon = useColorModeValue("red.500", "white");
  const blueIcon = useColorModeValue("blue.500", "white");
  const yellowIcon = useColorModeValue("yellow.500", "white");
  const bgIconButton = useColorModeValue("white", "whiteAlpha.100");
  const bgIconHover = useColorModeValue(
    { bg: "gray.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgIconFocus = useColorModeValue(
    { bg: "grey.100" },
    { bg: "whiteAlpha.100" }
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const shadow = useColorModeValue(
    "18px 17px 40px 4px rgba(112, 144, 176, 0.1)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card {...rest}>
      <Flex justify='space-between' px='10px' pt='5px' mb='25px' align='center'>
        <Text
          color={textColor}
          fontSize='lg'
          fontWeight='700'
          lineHeight='100%'>
          Your Wallet
        </Text>
      </Flex>
      <Tabs>
        <TabPanels mb='20px'>
          <TabPanel p='0px'>
            <PayPangeaCard number={wallet} cardnr={cardnr} ccv={ccv} />
          </TabPanel>
        </TabPanels>
        <TabList
          mb='20px'
          mx={{ base: "10px", lg: "30px" }}
          overflowX={{ sm: "unset", lg: "unset" }}
          border='0px solid transparent'>
          <Flex justify='center' w='100%'>
            <Tab
              p='0px'
              flexDirection='column'
              onClick={function () {
                setTabState("card1");
              }}
              me='18px'
              bg='unset'
              _selected={{
                bg: "none",
              }}
              _focus={{ border: "none" }}
              border='0px solid transparent !important'
              _active={{ bg: "none" }}
              minW='max-content'>
              <Box
                w='8px'
                height='8px'
                transition='0.1s linear'
                bg={tabState === "card1" ? "brand.500" : "secondaryGray.500"}
                borderRadius='50%'
              />
            </Tab>
          </Flex>
        </TabList>
      </Tabs>
      <Flex
        direction='column'
        bg={boxBg}
        p='16px 20px'
        borderRadius='14px'
        mb='38px'>
        <Text fontSize='sm' fontWeight='700' color={textColor}>
          Use for quick crypo payments
        </Text>
        <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
          You can accept any crypto asset to above wallet.
        </Text>
      </Flex>
      <Flex mx='auto' mb='40px'>
        <Flex
          direction='column'
          align='center'
          onClick={() => navigate("/admin/transfer")}
          me={{ base: "22px", md: "40px", "2xl": "36px" }}>
          <IconButton
            borderRadius='50%'
            bg={bgIconButton}
            _hover={bgIconHover}
            _active={bgIconFocus}
            _focus={bgIconFocus}
            w='56px'
            h='56px'
            mb='5px'
            boxShadow={shadow}
            icon={<Icon as={MdCached} color={iconColor} w='24px' h='24px' />}
          />
          <Text fontSize='sm' fontWeight='500' color={textColor}>
            Transfer
          </Text>
        </Flex>
        <Flex
          direction='column'
          align='center'
          onClick={() => navigate("/admin/top-up")}
          me={{ base: "22px", md: "40px", "2xl": "36px" }}>
          <IconButton
            borderRadius='50%'
            bg={bgIconButton}
            _hover={bgIconHover}
            _active={bgIconFocus}
            _focus={bgIconFocus}
            w='56px'
            h='56px'
            mb='5px'
            boxShadow={shadow}
            icon={<Icon as={MdAdd} color={yellowIcon} w='24px' h='24px' />}
          />
          <Text fontSize='sm' fontWeight='500' color={textColor}>
            Top Up
          </Text>
        </Flex>
        <Flex
          direction='column'
          align='center'
          onClick={() => navigate("/admin/request-payment")}
          me={{ base: "22px", md: "40px", "2xl": "36px" }}>
          <IconButton
            borderRadius='50%'
            bg={bgIconButton}
            _hover={bgIconHover}
            _active={bgIconFocus}
            _focus={bgIconFocus}
            w='56px'
            h='56px'
            mb='5px'
            boxShadow={shadow}
            icon={
              <Icon as={MdAttachMoney} color={greenIcon} w='24px' h='24px' />
            }
          />
          <Text fontSize='sm' fontWeight='500' color={textColor}>
            Request
          </Text>
        </Flex>
        <Flex 
          direction='column' 
          align='center'
          onClick={() => navigate("/admin/pay")}>
          <IconButton
            borderRadius='50%'
            bg={bgIconButton}
            _hover={bgIconHover}
            _active={bgIconFocus}
            _focus={bgIconFocus}
            w='56px'
            h='56px'
            mb='5px'
            boxShadow={shadow}
            icon={<Icon as={MdOutlineShoppingBasket} color={redIcon} w='24px' h='24px' />}
          />
          <Text fontSize='sm' fontWeight='500' color={textColor}>
            Pay
          </Text>
        </Flex>
      </Flex>
      {/* <Text fontSize='xl' color={textColor} fontWeight='700' mb='34px'>
        Your transactions
      </Text>
      <Transaction
        mb='26px'
        name='Public Transport'
        date='22 September 2022'
        sum='-$15.50'
        icon={
          <Icon
            as={MdOutlineDirectionsBus}
            color={iconColor}
            w='20px'
            h='18px'
          />
        }
      />
      <Transaction
        mb='26px'
        name='Grocery Store'
        date='18 September 2022'
        sum='-$42.28'
        icon={
          <Icon
            as={MdOutlineShoppingBasket}
            color={greenIcon}
            w='20px'
            h='18px'
          />
        }
      /> */}
    </Card>
  );
}
