import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  ChakraProvider,
} from '@chakra-ui/react';

import { GoogleOAuthProvider } from '@react-oauth/google';

import theme from "./theme/theme";

import { ProtectRoutes } from './_helpers/Auth';

import LoginPage from './views/auth/login/login'
import AuthPaypangea from './views/request/loginPaypangea/main';
import PayPageSdk from './views/request/payPageSdk/main';
import VerificationPage from './views/auth/verification/verification'
import Page404 from './views/common/404/404'

import Terms from './views/plane/terms/terms'
import Privacy from './views/plane/privacy/privacy'

import Pitch from './views/plane/pitch/pitch'

import NftClaim from './views/request/nftclaim/main';
import Landing from './views/plane/landing/landing'

import AdminLayout from "./layouts/admin";


function App() {


  return (
    <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'top-center', isClosable: true, duration: 4000, } }}>

        <Router>
          <Routes>
            <Route exact path="/" element={<LoginPage/>}/>

            <Route exact path="/auth/login" element={<LoginPage/>} />
            <Route exact path="/auth/verification" element={<VerificationPage/>} />

            <Route exact path="/terms-of-service" element={<Terms/>} />
            <Route exact path="/privacy-policy" element={<Privacy/>} />

            <Route exact path="/presentation" element={<Pitch/>} />

            <Route exact path="/request/pay" element={<PayPageSdk/>} />
            <Route exact path="/request/paysdk" element={
                <PayPageSdk/>
            } />
            <Route exact path="/request/auth" element={
                <AuthPaypangea/>
            } />

            <Route exact path="/claim" element={<NftClaim/>} />

            <Route element={ <ProtectRoutes /> }>
              <Route path='/admin/*' element={ <AdminLayout /> } />
            </Route>

            <Route path="*" element={<Page404/>} />
          </Routes>
        </Router>

    </ChakraProvider>
  );
}

export default App;
