import {useState, useEffect} from "react";
import { useNavigate } from 'react-router'
import validator from "validator";

// Chakra imports
import { 
  FormLabel,
  Button,
  Input, 
  Flex, 
  Text, 
  useToast,
  FormControl,
  FormErrorMessage,
  useColorModeValue } from "@chakra-ui/react";

// Custom components

import PinInputModal from '../../../../views/modals/pin/pin';
import PhoneNumberInput from '../../../../components/phoneNumberInput/phoneNumberInput';
import OutcomeModal from '../../../../views/modals/outcome/outcome';
import Overlay from '../../../../views/modals/overlay/overlay';
import Card from "../../../../components/card/Card.js";
import InputField from "../../../../components/fields/InputField";
import ButtonSelect from "../../../../components/buttonSelect/ButtonSelect";
import { ApiHeader } from '../../../../_helpers/ApiHeader';

// Assets

export default function Content(props) {
  const { title, wallet, symbols, ...rest } = props;

  const supportedNetworks = [
    'mainnet',
    'polygon',
    'arbitrum',
    'sepolia',
  ]

  const toast = useToast();
  const navigate = useNavigate();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgCard = useColorModeValue("white", "navy.700");

  const [transactionFee, setTransactionFee] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [maxAmount, setMaxAmount] = useState(0);

  const [amountPay, setAmountPay] = useState(0);
  const [amountExceeded, setAmountExceeded] = useState(false);

  const [recepient, setRecepient] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payTkn, setPayTkn] = useState('');
  const [payOutcome, setPayOutcome] = useState('');
  const [isOverlay, setIsOverlay] = useState(false);

  const [overlayText, setOverlayText] = useState('Coming soon');

  const [selectedType, setSelectedType] = useState('Email')

  const handleOverlay = (timeWait) => {
    setIsOverlay(true);

    setTimeout(() => {
      // After 1 second (1000 milliseconds), update the state to show content
      setIsOverlay(false);
    }, timeWait);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const submitPin = (submitedPin) => {
    console.log(`submitedPin ${submitedPin}`)
    setIsModalOpen(false);
    confirmPay(submitedPin)
  };

  const [isOutcomeModalOpen, setIsOutcomeModalOpen] = useState(false);

  const openOutcomeModal = () => {
    setIsOutcomeModalOpen(true);
  };

  const closeOutcomeModal = () => {
    setIsOutcomeModalOpen(false);

    navigate(`/admin/home`);
  };

  const [selectedNetwork, setSelectedNetwork] = useState(supportedNetworks[0]);
  const [availableTokens, setAvailableTokens] = useState([]);
  const [availableTokensSymbols, setAvailableTokensSymbols] = useState([]);
  const [selectedToken, setSelectedToken] = useState('');
  const [selectedTokenAddress, setSelectedTokenAddress] = useState('');

  const [startPayment, setStartPayment] = useState(false);
  
  useEffect(()=>{
    // getAvailableTokens(selectedNetwork);
    getTokenData(selectedNetwork);
  },[selectedNetwork])

  const getAvailableTokens = (network) => {

    if (!supportedNetworks.includes(network)) {
      return;
    }

    fetch(process.env.REACT_APP_API_URL+'pay/request-get-tokens', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : network
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data.tokens)
        setAvailableTokens(data.tokens)
        setAvailableTokensSymbols(data.tokens.map(token => token.symbol))

        setSelectedToken('')
        setSelectedTokenAddress('')

        if (data.tokens.length === 0 && !isOverlay) {
          setOverlayText('Coming soon')
          handleOverlay(1000);
        }
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }


  const [tokenData, setTokenData] = useState([])
  const [tokenSymbols, setTokenSymbols] = useState([])

  const getTokenData = (network) => {

    console.log(ApiHeader('auth'))

    fetch(process.env.REACT_APP_API_URL+'user/funds', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : network
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        // const symbols = data.userData.balances.map((item) => item.symbol);
        // setTokenSymbols(symbols);

        // setTokenData(data.userData.balances);

        console.log(data)
        setAvailableTokens(data.userData.balances)
        setAvailableTokensSymbols(data.userData.balances.map(token => token.symbol))

        setSelectedToken('')
        setSelectedTokenAddress('')

        if (data.userData.balances === 0 && !isOverlay) {
          setOverlayText('Coming soon')
          handleOverlay(1000);
        }

      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  useEffect(()=>{
    console.log('tokenData')
    console.log(availableTokens)
    console.log(symbols)

    console.log(`selectedToken ${selectedToken}`)

    if (selectedToken === '' && availableTokens.length > 0 && symbols?.length > 0) (
      setSelectedToken(symbols[0])
    )

    const foundItem = availableTokens.find((item) => item.symbol === selectedToken);

    console.log(foundItem)
    if (foundItem && foundItem.balance) {
      setMaxAmount(foundItem.balance);
      setTokenAddress(foundItem.token_address);
    } else {
      setMaxAmount(0);
      setTokenAddress('');
    }
  },[selectedToken])

  useEffect(()=>{
    getTransactionFee()
  },[availableTokens])

  const handleSelectChange = (event) => {
    console.log(event.target.value);
    setSelectedValue(event.target.value);

    const foundItem = tokenData.find((item) => item.symbol === event.target.value);
    console.log(foundItem)
    setMaxAmount(foundItem.balance);
    setTokenAddress(foundItem.token_address);
    getTransactionFee(foundItem.token_address);
  };

  const useMaxAmount = () => () => {
    let available = maxAmount;
    if (selectedValue===0) {
      available -= parseFloat(transactionFee)
    }
    changeAmountPay(available);
  };

  const changeAmountPay = (a) => {
    console.log(a)

    if (a===',' || a=== '.') {
      a='0.'
    }

    if ((a === ',' && (a.split('.').length - 1 > 0)) || (a.split('.').length - 1 > 1)) {
      return;
    }

    if (/^[0-9.]*$/.test(a)) {
      let amount = a.toString().replace(/,/g, '.');
      // const amount = Math.floor(parseFloat(modifiedInput) * 1000000) / 1000000;   
      if (amount.startsWith('0') && !amount.startsWith('0.')) {
        amount = amount.substring(1);
      }
  
      if (amount) {
        setAmountPay(amount);
      } else {
        setAmountPay(0);
      }
  
      setAmountExceeded(parseFloat(amount)>maxAmount)
    }

  }

  const getTransactionFee = (token_address) => {

    console.log(`getTransactionFee ${token_address}`)
 
    if (!token_address) {
      token_address = '0x00'
    }

    fetch(process.env.REACT_APP_API_URL+'pay/estimate-gas', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : selectedNetwork,
        "amount": "0",
        "token": token_address
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data)
        setTransactionFee(data.estimatedGas)
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const isEthereumAddress = (input) => {
    // Ethereum address validation logic (same as in the previous Node.js example)
    return /^0x[0-9a-fA-F]{40}$/.test(input);
  };

  const clickPay = () => {
    setStartPayment(true);

    if (validator.isEmpty(recepient)) {
      return;
    }

    if (validator.isEmpty(selectedNetwork)) {
      return;
    }

    if (validator.isEmpty(selectedToken)) {
      return;
    }

    if (validator.isEmpty(amountPay)) {
      return;
    }

    if (validator.isEmail(recepient)) {
      console.log('Email');
      payToEmail();
    } else if (validator.isMobilePhone(recepient, 'any', { strictMode: true })) {
      console.log('phone number');
      payToPhone();
    } else if (validator.isMobilePhone(recepient, 'any', { strictMode: false })) {
      toast({
        title: 'Error.',
        description: "Phone number must be in interantional format with + and country code",
        status: 'error',
      })
    } else if (isEthereumAddress(recepient)) {
      console.log('Ethereum address');
      payToCryptoAddress();
    } else {
      toast({
        title: 'Error.',
        description: "Unknown format, you can send crypto to email, phone or wallet address",
        status: 'error',
      })
    }

  };


  const payToEmail = () => {

    fetch(process.env.REACT_APP_API_URL+'pay/send-email', {
      method: 'POST',
      body: JSON.stringify({
        "email": recepient,
        "amount": amountPay.toString(),
        "token": tokenAddress,
        "chain" : selectedNetwork,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data)
        setPayTkn(data.paymentData.tkn)

        openModal();

      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const payToPhone = () => {

    fetch(process.env.REACT_APP_API_URL+'pay/send-phone', {
      method: 'POST',
      body: JSON.stringify({
        "phone": recepient,
        "amount": amountPay.toString(),
        "token": tokenAddress,
        "chain" : selectedNetwork,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data)
        setPayTkn(data.paymentData.tkn)

        openModal();

      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const payToCryptoAddress = () => {

    fetch(process.env.REACT_APP_API_URL+'pay/send-address', {
      method: 'POST',
      body: JSON.stringify({
        "address": recepient,
        "amount": amountPay.toString(),
        "token": tokenAddress,
        "chain" : selectedNetwork,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data)
        setPayTkn(data.paymentData.tkn)

        openModal();

      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const confirmPay = (pin) => {
    setIsOverlay(true);
    setOverlayText("Processing...")

    fetch(process.env.REACT_APP_API_URL+'pay/verify-payment', {
      method: 'POST',
      body: JSON.stringify({
        "tkn": payTkn,
        "passkey": pin,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data)
        setPayOutcome('SUCCESS');
      } else {
        setPayOutcome('FAILURE');
      }
      openOutcomeModal(true);
      setIsOverlay(false);
    })
    .catch(err => {
        console.log("fetch error: " + err);

      setIsOverlay(false);
        toast({
          title: 'Error.',
          description: "Something went wrong, please try again",
          status: 'error',
        })
    });
  }

  return (
    <>
      <Flex direction='column' p={{base:"10px", md:"60px"}}>
        <Card
          backgroundRepeat='no-repeat'
          bg={bgCard}
          p='30px'
          mb='30px'
          mt='-100px'>
          <Flex direction={{base:"column", md:"row"}}>
            <Flex 
            direction='column' 
            me='auto' 
            width={{base:"100%", md:"63%"}}
            mb={{base:"30px", md:"0px"}}>
              <ButtonSelect 
                  mb='24px'
                  mt='8px'
                  label='Select the network'
                  value={selectedNetwork}
                  isInvalid={selectedNetwork==='' && startPayment}
                  errorMessage='Network is required'
                  onChange={(e) => {
                    setStartPayment(false);
                    getTokenData(e);
                    setSelectedNetwork(e);
                    setAvailableTokens([]);
                    setAvailableTokensSymbols([]);
                    setSelectedToken('');
                    setSelectedTokenAddress('');
                  }}
                  fixedElements={[]}
                  baseOptions={supportedNetworks}
              />
              <ButtonSelect 
                mb='24px'
                mt='8px'
                label='Select the token'
                isInvalid={selectedToken==='' && startPayment}
                errorMessage='Token is required'
                value={selectedToken}
                onChange={(e) => {
                  console.log(e)
                  setStartPayment(false);

                  setSelectedToken(e);

                  const token = availableTokens.find(t => t.symbol === e);
                  console.log(token);
                  const address = token ? token.token_address : '';

                  console.log(address);

                  setSelectedTokenAddress(address);
                }}
                fixedElements={['USDT','USDC']}
                baseOptions={availableTokensSymbols}
              />

              <FormControl 
                  mb="24px" 
                  mt='8px'
                  isInvalid={amountPay===0 && startPayment}>

                  <Flex 
                    direction="row"
                    justifyContent={'space-between'}
                    alignItems={'baseline'}>
                    <FormLabel
                      display='flex'
                      ms='10px'
                      htmlFor='amount'
                      fontSize='sm'
                      color={textColor}
                      fontWeight='bold'
                      _hover={{ cursor: "pointer" }}>
                      {'Amount to transfer'}
                    </FormLabel>
                    <Button
                      variant="outline"
                      borderRadius="full"
                      borderColor={textColor}
                      color={textColor}
                      size="xs"
                      onClick={useMaxAmount()}
                    >
                      Max
                    </Button>
                  </Flex>
                  <FormLabel
                    display='flex'
                    ms='12px'
                    htmlFor='amount'
                    fontSize='sm'
                    color={textColor}
                    fontWeight='400'>
                    {`Available ${maxAmount}`}
                  </FormLabel>
                  <Input
                    {...rest}
                    type='text'
                    id='amount'
                    fontWeight='500'
                    variant='main'
                    placeholder='Amount'
                    value={amountPay}
                    _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                    h='44px'
                    maxh='44px'
                    onChange={(e) => {
                      setStartPayment(false);
                      changeAmountPay(e.target.value)} 
                    }
                  />
                  <FormErrorMessage>Amount is required.</FormErrorMessage>
              </FormControl>

              <ButtonSelect 
                mb='24px'
                mt='8px'
                label='Transfer to:'
                isInvalid={selectedType==='' && startPayment}
                errorMessage='Token is required'
                value={selectedType}
                onChange={(e) => {
                  console.log(e)
                  setSelectedType(e);

                  setSelectedToken(e);
                }}
                fixedElements={[]}
                baseOptions={['Email','Phone','Wallet']}
              />
              {selectedType === 'Phone' ? (
                <PhoneNumberInput
                  mb='0px'
                  mt='8px'
                  id='recepient'
                  label='Transfer to:'
                  placeholder='Phone number'
                  value={recepient}
                  isInvalid={recepient==='' && startPayment}
                  errorMessage='Valid phone number is required'
                  onChange={(e) => {
                    setStartPayment(false);
                    setRecepient(e.replace(/\s+/g, ''));
                  }}
                />
              ):(
                <InputField
                  mb='0px'
                  mt='8px'
                  id='recepient'
                  label='Transfer to:'
                  placeholder='Recepient'
                  value={recepient}
                  isInvalid={recepient==='' && startPayment}
                  errorMessage='Valid recepient is required'
                  onChange={(e) => {
                    setStartPayment(false);
                    setRecepient(e.target.value);
                  }}
                />
              )}
            </Flex>
            <Flex direction="column" justifyContent="space-between" textAlign='right'>
              <div>
                <Text
                  w='100%'
                  mb='4px'
                  fontSize='md'
                  color='secondaryGray.600'
                  fontWeight='400'>
                  Amount to transfer
                </Text>
                <Text color={textColor} fontSize='18px' fontWeight='700'>
                  {amountPay} {selectedValue}
                </Text>
                <Text
                  w='100%'
                  mb='4px'
                  fontSize='sm'
                  fontWeight='400'
                  color='secondaryGray.600'>
                  Transaction fee:<br></br>
                  {transactionFee} {symbols?symbols[0]:''}
                </Text>
                {amountExceeded && (
                  <Text
                  w='100%'
                  mb='4px'
                  fontSize='sm'
                  color='#FF416C'
                  fontWeight='400'>
                    Not enough funds
                  </Text>
                )}
              </div>
              {!amountExceeded && (
                <Button
                  w='100%'
                  fontSize='md'
                  p='6px 12px'
                  bg='linear-gradient(108.54deg, #FF416C 6.56%, #FF4B2B 95.2%)'
                  color='white'
                  textAlign='center'
                  borderRadius='10px'
                  fontWeight='700'
                  onClick={()=>clickPay()}>
                    Pay
                </Button>
              )}
            </Flex>
          </Flex>
        </Card>
      </Flex>


      <PinInputModal isOpen={isModalOpen} onClose={closeModal} submitPin={submitPin} recepient={recepient} amount={amountPay + " " + selectedValue}/>

      <OutcomeModal isOpen={isOutcomeModalOpen} onClose={closeOutcomeModal} outcome={payOutcome}/>

      <Overlay isOpen={isOverlay} text={overlayText}/>
    </>
  );
}
