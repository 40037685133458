import React from "react";

// Chakra imports
import {
  Flex,
  Spinner,
  Icon,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";

// Custom components
import Transaction from "../../../../../../components/dataDisplay/Transaction";
import ButtonSelect from "../../../../../../components/buttonSelect/ButtonSelect";
import TokenBalance from "../../../../../../components/dataDisplay/TokenBalance";
import Card from "../../../../../../components/card/Card.js";

// Assets
import balanceImg from "../../../../../../assets/img/balanceImg.png";

function Balance(props) {
  const { balance, tokenbalances, chain, setChain, isLoading } = props;
  
  const supportedNetworks = [
    'mainnet',
    'polygon',
    'arbitrum',
    'sepolia',
  ]

  // Ellipsis modals

  // Chakra Color Mode
  const balanceBg = useColorModeValue("brand.900", "#1B254B");
  
  return (
    <Card direction='column' w='100%'>
      <ButtonSelect 
        mb='24px'
        mt='8px'
        label='Select the network'
        isInvalid={chain===''}
        errorMessage='Please select a network'
        value={chain}
        onChange={(e) => {
          setChain(e);
        }}
        fixedElements={[]}
        baseOptions={supportedNetworks}
      />
      <Flex
        justify='space-between'
        p='20px'
        mb='20px'
        borderRadius='16px'
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition='right'
        bgSize='cover'>
        <Flex align='center' justify='center' w='100%'>
          <Flex flexDirection='column' me='20px' w='100%'>
            <Text color='white' fontSize='sm' fontWeight='500'>
              Account Balance
            </Text>
            {isLoading && (
              <Flex w='100%' mt='20px' mb='20px' align='center' justify='center'>
                <Spinner
                  color='white'
                  thickness='4px'
                  speed='0.65s'
                  size='xl'
                />
              </Flex>
            )}
            {!isLoading && (
              <Text
                color='white'
                fontSize={{ sm: "18px", lg: "26px" }}
                fontWeight='700'
                lineHeight='100%'>
                {balance?.balance} {balance?.symbol}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Text color='secondaryGray.600' fontWeight='500' fontSize='sm' mb='10px'>
        Token Balances
      </Text>
      <Flex direction='column'>

        {tokenbalances?.map((row, index) => {
          return (
            <TokenBalance
              key={index}
              mb='20px'
              name={row.name}
              symbol={row.symbol}
              decimals={row.decimals}
              balance={row.balance}
            />
          )
        })}
      </Flex>
    </Card>
  );
}

export default Balance;