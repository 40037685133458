

import { useState, useEffect } from "react";
// Chakra imports
import { 
  Flex, 
  SimpleGrid, 
  Text,
  Icon,
  Image,
  useColorModeValue } from "@chakra-ui/react";
// Custom components

import Overlay from '../../../views/modals/overlay/overlay';
import Banner from "../../../views/dashboard/memories/components/Banner";

import Project from "../../../components/card/Project";
import { ApiHeader } from '../../../_helpers/ApiHeader';


import Project1 from "../../../assets/img/Nft1.png";
import Project2 from "../../../assets/img/Nft2.png";
import Project3 from "../../../assets/img/Nft3.png";

export default function Default() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const [isOverlay, setIsOverlay] = useState(false);
  const [memories, setMemories] = useState([]);

  const handleOverlay = (timeWait) => {
    setIsOverlay(true);

    setTimeout(() => {
      // After 1 second (1000 milliseconds), update the state to show content
      setIsOverlay(false);
    }, timeWait);
  };

  useEffect(()=>{
    getTransactionsData()
  },[])

  const getTransactionsData = () => {

    fetch(process.env.REACT_APP_API_URL+'user/get-memories', {
      method: 'GET',
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {

      console.log(data)

      if (data.status === 1) {
        console.log(data.userData.balances[0].result)
        setMemories(data.userData.balances[0].result);
      } else {
      }
      
    })
    .catch(err => {
      console.log("fetch error: " + err);
    });
  }

  return (
    <Flex
      direction="column"
      pt={{ base: "0px", md: "80px", xl: "80px" }}>
      <Banner />

      <Flex
        mt='45px'
        mb='0px'
        justifyContent='space-between'
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }}>
        {/* <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
          Trending Projects
        </Text> */}
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px'>
        {memories.map((memory, index) => {
          const metadata = JSON.parse(memory?.metadata);
           return (
          <Project
            key={index}
            name={metadata?.name}
            author={metadata?.description || 'Updating, can take up to 15min'}
            image={memory.media?.original_media_url}
            pagelink={`https://opensea.io/assets/matic/${memory.token_address}/${memory.token_id}`}
          />
        )})}
      </SimpleGrid>
      <Overlay isOpen={isOverlay} text={'Coming soon ...'}/>
    </Flex>
  );
}
