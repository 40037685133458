export const tableColumnsMostVisited = [
  {
    Header: "Address",
    accessor: "to_address",
  },
  {
    Header: "Type",
    accessor: "status",
  },
  {
    Header: "Date",
    accessor: "block_timestamp",
  },
  {
    Header: "Amount",
    accessor: "value",
  },
];
