import {useState, useEffect} from "react";
import { useNavigate } from 'react-router';
import transakSDK from '@transak/transak-sdk';
import QRCode from 'qrcode.react';

// Chakra imports
import { 
  FormLabel,
  Button,
  Input, 
  Flex, 
  Text, 
  useToast,
  useBreakpointValue,
  useColorModeValue } from "@chakra-ui/react";

// Custom components

import PinInputModal from '../../../../views/modals/pin/pin';
import OutcomeModal from '../../../../views/modals/outcome/outcome';
import Overlay from '../../../../views/modals/overlay/overlay';
import Card from "../../../../components/card/Card.js";
import { VSeparator, HSeparator } from "../../../../components/separator/Separator";
import ButtonSelect from "../../../../components/buttonSelect/ButtonSelect";
import SelectField from "../../../../components/fields/SelectField";
import { ApiHeader } from '../../../../_helpers/ApiHeader';

// Assets

export default function Content(props) {
  const { wallet, ...rest } = props;

  const supportedNetworks = [
    'ethereum',
    'polygon',
    'arbitrum',
    'sepolia',
  ]

  const toast = useToast();
  const navigate = useNavigate();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgCard = useColorModeValue("white", "navy.700");
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const [selectedValue, setSelectedValue] = useState(supportedNetworks[0]);

  const walletDisplayText = useBreakpointValue({
    base: `${wallet.substr(0,8)}...${wallet.slice(-6)}`,
    md: wallet,
  });

  const [amountPay, setAmountPay] = useState(0);
  const [amountExceeded, setAmountExceeded] = useState(false);


  const [payOutcome, setPayOutcome] = useState('');
  const [isOverlay, setIsOverlay] = useState(false);

  const [isOutcomeModalOpen, setIsOutcomeModalOpen] = useState(false);

  const openOutcomeModal = () => {
    setIsOutcomeModalOpen(true);
  };

  const closeOutcomeModal = () => {
    setIsOutcomeModalOpen(false);

    navigate(`/admin/home`);
  };

  const handleSelectChange = (event) => {
    console.log(event.target.value);
    setSelectedValue(event.target.value);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(wallet)
    toast({
      title: 'Success.',
      description: "Wallet address copied to clipboard",
      status: 'success',
    })
  };


  let transak = new transakSDK({
    apiKey: process.env.REACT_APP_TRANSAK_ID,
    environment: process.env.REACT_APP_TRANSAK_ENVIRONMENT
    ,
    defaultNetwork: selectedValue,
    defaultCryptoCurrency: 'USDC',
    walletAddress: wallet,
  });

  // To get all the events
  transak.on(transak.ALL_EVENTS, (data) => {
    console.log(data);
  });

  // This will trigger when the user closed the widget
  transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
    transak.close();
  });

  // This will trigger when the user marks payment is made
  transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
    console.log(orderData);
    transak.close();
  });

  useEffect(() => {
    // Cleanup code
    return () => {
      transak.close();
    };
  }, []);

  const handleSubmit = () => {
    // setIsOverlay(true);

    // setTimeout(() => {
    //   // After 1 second (1000 milliseconds), update the state to show content
    //   setIsOverlay(false);
    // }, 1000);

    transak.init();

    
  };

  return (
    <>
      <Flex direction='column' p={{base:"10px", md:"60px"}}>
        <Card
          backgroundRepeat='no-repeat'
          bg={bgCard}
          p='30px'
          mb='30px'
          mt='-100px'>

          
          <Button onClick={handleSubmit} 
              variant='brand' 
              size="lg" 
              w="100%">
              Buy crypto
          </Button>
          <HSeparator
            my='20px'
            bg={paleGray}
            display={"flex"}
          />
          <Flex direction={{base:"column", md:"row"}}>
            <Flex 
            direction='column' 
            me='auto' 
            width={{base:"100%", md:"58%"}}
            mb={{base:"30px", md:"0px"}}>
              <ButtonSelect 
                  mb='24px'
                  mt='8px'
                  label='Select the network'
                  value={selectedValue}
                  isInvalid={selectedValue===''}
                  errorMessage='Network is required'
                  onChange={(e) => {
                    console.log(e)
                    setSelectedValue(e);
                  }}
                  fixedElements={[]}
                  baseOptions={supportedNetworks}
              />
              <Flex 
                direction='column' 
                mb="24px" 
                mt='8px'>

                <Flex 
                  direction="row"
                  justifyContent={'space-between'}
                  alignItems={'baseline'}>
                  <FormLabel
                    display='flex'
                    ms='10px'
                    htmlFor='amount'
                    fontSize='sm'
                    color={textColor}
                    fontWeight='bold'
                    _hover={{ cursor: "pointer" }}>
                    {'Wallet address'}
                  </FormLabel>
                  <Button
                    variant="outline"
                    borderRadius="full"
                    borderColor={textColor}
                    color={textColor}
                    size="xs"
                    onClick={() => copyToClipboard()}
                  >
                    Copy
                  </Button>
                </Flex>
                <FormLabel
                  display='flex'
                  ms='12px'
                  htmlFor='amount'
                  fontSize='sm'
                  color={textColor}
                  fontWeight='400'>
                  {walletDisplayText}
                </FormLabel>
              </Flex>
            </Flex>
            <Flex direction="column" justifyContent="space-between" textAlign='right'>
                <Text
                  w='100%'
                  mb='20px'
                  fontSize='md'
                  color='secondaryGray.600'
                  fontWeight='400'>
                  Scan the QR code:
                </Text>
                <div style={{ background: 'white', padding: '15px', margin: 'auto' }}>
                  <QRCode value={`ethereum:${wallet}`} size={180} />
                </div>
            </Flex>
          </Flex>
        </Card>
      </Flex>


      <OutcomeModal isOpen={isOutcomeModalOpen} onClose={closeOutcomeModal} outcome={payOutcome}/>

      <Overlay isOpen={isOverlay} text={'Coming soon'}/>
    </>
  );
}
