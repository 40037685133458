import { useState, useEffect } from "react";

// Chakra imports
import { Flex, useToast, Box, Image, Text, Button } from "@chakra-ui/react";
// Custom components
import Card from "./Card.js";

// Assets
import bgMastercard from "../../assets/img/Debit.png";
import payPangeaLogo from "../../assets/img/PayPangeaLogoBlack.png";
import payPangeaSign from "../../assets/img/PayPangeaCardLogo.png";

import PinInputModal from '../../views/modals/pin/exportpin';
import PrivateKeyModal from '../../views/modals/privatekey/privatekey';

import { ApiHeader } from '../../_helpers/ApiHeader';

export default function PayPangeaCard(props) {
  const { number, cardnr, ccv,  ...rest } = props;
  const toast = useToast();

  const [seeWallet, setSeeWallet] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pkey, setPkey] = useState('');
  const [address, setAddress] = useState('');
  const [isPkeyOpen, setIsPkeyOpen] = useState(false);

  const formatCreditCardNumber = (number) => {
    return number.replace(/(\d{4}(?=\d{1,12}))/g, "$1 ");
  } 

  const exportWallet = () => {
    console.log("export wallet");
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closePkeyModal = () => {
    setIsPkeyOpen(false);
  };

  const submitPin = (submitedPin) => {
    console.log(`submitedPin ${submitedPin}`)
    setIsModalOpen(false);
    getKey(submitedPin)
  };

  const getKey = (pin) => {
    
    fetch(process.env.REACT_APP_API_URL+'user/export-pkey', {
      method: 'POST',
      body: JSON.stringify({
        "passkey" : pin,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)
      if (data.status===1) {
        setPkey(data.userData.pkey)
        setAddress(data.userData.address)
        setIsPkeyOpen(true)
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
    });
  }

  return (
    <>
    <Card
      borderRadius='20px'
      backgroundImage={bgMastercard}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      boxShadow='0px 32px 41px -18px rgba(242, 134, 134, 0.4)'
      alignSelf='center'
      w={{ base: "100%", md: "60%", xl: "99%" }}
      bgPosition='10%'
      mx='auto'
      position="relative"
      {...rest}>

      <div style={{ paddingTop: "63.04%" }}></div>

      <Box 
        color='white' 
        h='100%' 
        w='100%'
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        p='20px'
        >
          <Image 
            position='absolute'
            top='20px'
            left='20px'
            src={payPangeaLogo} 
            h={{ sm: "15%", lg: "16%" }}
            alt="PayPangea Logo" />

          {!seeWallet && (
          <Image 
            position='absolute'
            bottom='20px'
            right='20px'
            src={payPangeaSign} 
            h="20%" 
            alt="PayPangea Logo" />
          )}
          <Button 
            position='absolute'
            top='20px'
            right='20px'
            colorScheme="brand"
            variant="outline" 
            fontSize={{ sm: "sm", lg: "2vw", xl: "0.8vw" }}
            onClick={() => setSeeWallet(!seeWallet)}
            size="sm" >
            View Details
          </Button>
          {seeWallet && (
            <Flex
              position='absolute'
              bottom='30px'
              w='calc(100% - 40px)' 
              wrap={'wrap'}
              left='20px' >
              <Flex 
                color='white' 
                textAlign='left' 
                direction="row"
                w='calc(100%)' 
                justifyContent={'space-between'}
                alignItems={'baseline'}>
                <Text
                  fontSize='sm'
                  color='white' 
                  fontWeight='bold'>
                  {'Wallet address'}
                </Text>
                <Button
                  variant="outline"
                  borderRadius="full"
                  colorScheme="brand"
                  mb='10px'
                  size="xs"
                  onClick={() => {
                    navigator.clipboard.writeText(number)
                    toast({
                      title: 'Success.',
                      description: "Wallet address copied to clipboard",
                      status: 'success',
                    })
                  }}
                >
                  Copy
                </Button>
              </Flex>
              <Text 
                color='white' 
                textAlign='left' 
                w='calc(100%)' 
                fontSize={{ sm: "md", lg: "2vw", xl: "1vw" }}
                fontWeight='bold'>
                {number}
              </Text>

              <Flex 
                  direction="row"
                  w='calc(100%)' 
                  mt='10px'
                  justifyContent={'flex-end'}
                  alignItems={'baseline'}>
                  <Button
                    variant="outline"
                    borderRadius="full"
                    colorScheme="brand"
                    size="xs"
                    onClick={() => exportWallet()}
                  >
                    Export wallet
                  </Button>
                </Flex>
            </Flex>
          )}
          {!seeWallet && (
            <>
              <Text 
                position='absolute'
                bottom='60px'
                left='20px' 
                color='white' 
                textAlign='left' 
                w='calc(100% - 40px)' 
                mb='0px' 
                mt='0px'
                fontSize={{ sm: "xl", lg: "4vw", xl: "1.6vw" }} 
                fontWeight='bold'>
                {formatCreditCardNumber(cardnr)}
              </Text>
              <Text 
                position='absolute'
                bottom='20px'
                left='20px' 
                color='white' 
                textAlign='left' 
                w='calc(100% - 40px)' 
                mb='0px' 
                mt='0px'
                fontSize={{ sm: "l", lg: "2vw", xl: "1.2vw" }} 
                fontWeight='bold'>
                CVC: {ccv}
              </Text>
            </>
          )}
      </Box>
    </Card>


      <PinInputModal isOpen={isModalOpen} onClose={closeModal} submitPin={submitPin}/>
      <PrivateKeyModal isOpen={isPkeyOpen} onClose={closePkeyModal} pkey={pkey} wallet={address} />
    </>
    
  );
}
