import { 
    Text, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    Card, 
    ModalOverlay, 
    Button, 
    Icon,
    Flex,
    Box,
    Grid,
    GridItem } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { FaMoneyBillWave, FaWallet } from 'react-icons/fa';
import { FiRefreshCcw } from "react-icons/fi";

import InvoiceBg from "../../../assets/img/InvoiceBg.png";
import React from "react";

import { HSeparator } from "../../../components/separator/Separator";

const TopupModal = ({isOpen, onClose, closeBuyCrypto, closeTransferCrypto, outcome, hasTopedUp, onRefresh, walletBalance = '-', coin = '', amount = '-', message = ''}) => {

    const clickRefresh = () => {
        onRefresh();
    }   
  return (
    <Modal 
        isCentered 
        motionPreset='slideInBottom'
        isOpen={isOpen} 
        onClose={onClose} 
        size="xs">
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropBlur='8px'/>
        <ModalContent>
            <Card
                backgroundImage={InvoiceBg}
                backgroundRepeat='no-repeat'
                bgSize='cover'
                bgPosition='10%'
                borderRadius="5px 5px 0 0" 
                p="20px"
                pb="20px">
                
                <Text fontSize="xl" mt="0px" fontWeight='bold'>
                    Top Up your Account
                </Text>
            </Card>
            <ModalCloseButton />
            <ModalBody p={10} textAlign="center">
                {hasTopedUp && (
                    <>
                        <Text textAlign="left" fontSize="md" mb="25px" fontWeight='bold'>
                            You submitted your payment, we are waiting for it to be confirmed on blockchain.
                        </Text>
                    </>
                )}

                <Grid templateColumns="40px 1fr 20px" gap={4} mb="20px" w="100%" alignItems="center">
                    <GridItem display="flex" justifyContent="center" alignItems="center">
                        <WarningTwoIcon color="red.500" w="40px" h="40px" />
                    </GridItem>

                    <GridItem>
                        <Text color='white' fontSize='sm' fontWeight='500'>
                        Your balance:
                        </Text>
                        <Text color='white' fontSize={{ sm: "18px", lg: "26px" }} fontWeight='700' lineHeight='100%'>
                        {walletBalance} {coin}
                        </Text>
                    </GridItem>

                    <GridItem 
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center" 
                        cursor='pointer' 
                        onClick={clickRefresh}>
                        <Icon 
                            as={FiRefreshCcw} 
                            color="green.500" 
                            w="20px" 
                            h="40px"
                            style={hasTopedUp ? { display: 'none' } : {}}
                        />
                    </GridItem>
                </Grid>

                <Grid templateColumns="40px 1fr 20px" gap={4} mb="20px" w="100%" alignItems="center">
                    <GridItem display="flex" justifyContent="center" alignItems="center">
                        <Icon as={FaMoneyBillWave} color="green.500" w="40px" h="40px" />
                    </GridItem>

                    <GridItem>
                        <Text color='white' fontSize='sm' fontWeight='500'>
                        Needed:
                        </Text>
                        <Text color='white' fontSize={{ sm: "18px", lg: "26px" }} fontWeight='700' lineHeight='100%'>
                        {amount} {coin}
                        </Text>
                    </GridItem>
                </Grid>

                {hasTopedUp && (
                    <Icon mb="40px" mt='25px'
                        as={FiRefreshCcw} 
                        color="green.500" 
                        w="80px" 
                        h="80px"
                        style={hasTopedUp ? { animation: 'rotateReverse 2s linear infinite' } : {}}
                    />
                )}

                {!hasTopedUp && (
                    <>
                        <Button onClick={closeTransferCrypto} 
                            variant='brand' 
                            mt="20px" 
                            size="lg" 
                            w="100%">
                            Wallet Connect
                        </Button>
        
                        <Button onClick={closeBuyCrypto} 
                            variant='brand' 
                            mt="20px" 
                            size="lg" 
                            w="100%">
                            Buy Crypto
                        </Button>

                        <Flex align='center' mt='15px' mb='15px'>
                            <HSeparator />
                            <Text mx='14px'>
                                or
                            </Text>
                            <HSeparator />
                        </Flex>
                    </>
                )}


                <Button onClick={onClose} 
                    variant='brand' 
                    size="lg" 
                    w="100%">
                    Finish
                </Button>

            </ModalBody>
          </ModalContent>
    </Modal>
  );
}

export default TopupModal;
