import { useEffect, useState } from "react";
import { useNavigate } from 'react-router'
import { useLocation } from "react-router-dom";

import { ApiHeader } from '../../../_helpers/ApiHeader';
import { SetJwtAuth } from '../../../_helpers/Auth';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  PinInput,
  PinInputField,
  Heading,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import DefaultAuth from "../../../layouts/auth/auth";

import SetPinModal from '../../../views/modals/setcode/setcode';

// Assets
import illustration from "../../../assets/img/auth.png";

const VerificationPage = () => {

  const toast = useToast();
  const navigate = useNavigate()

  const [username, setUsername] = useState('')
  const [token, setToken] = useState('')
  const [pin, setPin] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();

    useEffect(()=>{
        setUsername(atob(query.get('m')));
        setToken(atob(query.get('t')));

        console.log(atob(query.get('m')))
    },[])

  const textColor = useColorModeValue("navy.700", "white");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const [isLoading, setIsLoading] = useState(false)

  const handleComplete = (value) => {
    setPin(value)
  };

  const pinSet = (wallet) => {
    navigate('/admin/home');
  }

  const verifyPasscode = (event) => {
    event.preventDefault();
    if (pin === '') {
      toast({
        title: 'Error.',
        description: "Please enter pin",
        status: 'error',
      })
      return;
    }
    setIsLoading(true);


    fetch(process.env.REACT_APP_API_URL+'auth/login-code', {
      method: 'POST',
      body: JSON.stringify({
        "code" : pin,
        "token" : token
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        // toast({
        //   title: 'Email verified',
        //   description: "Thank you for verifying your email",
        //   status: 'success',
        // })
        SetJwtAuth(data.tokens.access.token);
        // setTimeout(() => {
          if (data.newUser) {
            openModal();
          } else {
            navigate('/admin/home')
          }
        // }, 500);
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  return (
    <>
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          w='100%'
          maxW='max-content'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px", lg: "90px", xl: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", lg: "14vh", xl: "22vh" }}
          flexDirection='column'>
          <Box me='auto' mb='34px'>
            <Heading
              color={textColor}
              fontSize='36px'
              mb='16px'
              mx={{ base: "auto", lg: "unset" }}
              textAlign={{ base: "center", lg: "left" }}>
              Verification
            </Heading>
            <Text
              color='gray.400'
              fontSize='md'
              maxW={{ base: "95%", md: "100%" }}
              mx={{ base: "auto", lg: "unset" }}
              textAlign={{ base: "center", lg: "left" }}>
              Enter the code we just sent to you.
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "395px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <FormControl>
              <Flex justify='center'>
                <PinInput mx='auto' onComplete={handleComplete}>
                  <PinInputField
                    fontSize='36px'
                    color={textColor}
                    borderRadius='16px'
                    borderColor={borderColor}
                    h={{ base: "63px", md: "95px" }}
                    w={{ base: "63px", md: "95px" }}
                    me='10px'
                  />
                  <PinInputField
                    fontSize='36px'
                    color={textColor}
                    borderRadius='16px'
                    borderColor={borderColor}
                    h={{ base: "63px", md: "95px" }}
                    w={{ base: "63px", md: "95px" }}
                    me='10px'
                  />
                  <PinInputField
                    fontSize='36px'
                    color={textColor}
                    borderRadius='16px'
                    borderColor={borderColor}
                    h={{ base: "63px", md: "95px" }}
                    w={{ base: "63px", md: "95px" }}
                    me='10px'
                  />
                  <PinInputField
                    fontSize='36px'
                    color={textColor}
                    borderRadius='16px'
                    borderColor={borderColor}
                    h={{ base: "63px", md: "95px" }}
                    w={{ base: "63px", md: "95px" }}
                    me='10px'
                  />
                  <PinInputField
                    fontSize='36px'
                    color={textColor}
                    borderRadius='16px'
                    borderColor={borderColor}
                    h={{ base: "63px", md: "95px" }}
                    w={{ base: "63px", md: "95px" }}
                    me='10px'
                  />
                  <PinInputField
                    fontSize='36px'
                    color={textColor}
                    borderRadius='16px'
                    borderColor={borderColor}
                    h={{ base: "63px", md: "95px" }}
                    w={{ base: "63px", md: "95px" }}
                  />
                </PinInput>
              </Flex>

              <Button
                fontSize='14px'
                variant='brand'
                borderRadius='16px'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                isLoading={isLoading}
                onClick={verifyPasscode}
                mt='12px'>
                Unlock
              </Button>
            </FormControl>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='0px'>
              <Text
                color={textColorDetails}
                fontWeight='400'
                fontSize='14px'
                mx={{ base: "auto", lg: "unset" }}
                textAlign={{ base: "center", lg: "left" }}>
                Haven't received it?<br></br>
                We've send the code to <span style={{color: textColorBrand}}>{username}</span>.<br></br>
                {/* Please check your spam folder if it's not in your inbox.<br></br>
                If you do not find it in spam folder, please check the spelling of your email address.<br></br> */}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>

      <SetPinModal isOpen={isModalOpen} onClose={closeModal} pinSet={pinSet}/>
    </>
  );
}

export default VerificationPage;
