import React, {useState, useEffect, useRef} from 'react';
import { Box, Button, Card, Flex, Text, useColorModeValue, Heading, Image, Link, useToast } from '@chakra-ui/react';

import InvoiceBg from "../../../assets/img/InvoiceBg.png";
import PayPangeaPresentation from "../../../assets/img/paypangea-presentation.jpg";

function PitchPage() {
    
    const toast = useToast();

    const [logedIn, setLogedIn] = useState(false);
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const bgCard = useColorModeValue("white", "navy.700");

    const payPangea = useRef(null);

    const iframeScreen = () => {
        return (
            <Box height="100vh" width="100vw">
                <iframe
                src="https://www.canva.com/design/DAGD-TZt530/bJohyMdpU_MZrbMjcblJ7Q/view?embed"
                title="Pitch"
                width="100%"
                height="100%"
                style={{ border: 'none' }}
                ></iframe>
            </Box>
        )
    }

    useEffect(() => {
        if (document.getElementById('paypangea-script')) {
          console.log('Script already loaded');
          return;
        }

        const script = document.createElement('script');
        script.src = 'https://sdk.paypangea.com/sdk.js?ver=4'; // Corrected variable name SKD_PATH -> SDK_PATH
        script.id = 'paypangea-script'; // Set an ID to avoid duplicates
        script.async = true;
        script.onload = initializePayPangea;
        document.body.appendChild(script);
        console.log(`script loaded`);
    
        return () => {
            document.body.removeChild(script); // Cleanup script when the component unmounts
        };
    }, []); // Empty dependency array means this runs once on mount

    const initializePayPangea = () => {

        if (payPangea.current) {
            return;
        }
        console.log(`paypangea start intialisation`);
        // eslint-disable-next-line no-undef
        payPangea.current = new PayPangea({
          apiKey: '2321349-vnregy88-7yf78dsgf-rhf87y',
          environment: 'PRODUCTION',
        });
    
        const logEvent = (event) => {
          return (data) => {
            console.log(`Event '${event}' received from PayPangea`, data);
          };
        };
    
        const events = ['success', 'error', 'cancel', 'update'];
        events.forEach(event => {
          payPangea.current.on(event, logEvent(event));
        });
    
        payPangea.current.on("success", (data) => {
          console.log('Payment was successful');
          console.log(data);
          console.log(data.outcome);
          setLogedIn(true);
          registerBlocksee(data.outcome.wallet, data.outcome.token);
        });
        console.log(`paypangea intialised`);
    };

    const RegisterClick = () => {
        console.log('click');
        payPangea.current.showLogin({});
    }

    const registerBlocksee = (wallet, token) => {
        fetch('https://api.blocksee.aquisify.com/v1/chat/paypangea-login', {
            method: 'POST',
            body: JSON.stringify({
                "wallet" : wallet,
                "token" : token,
                "projectid" : '65e0d41a5ae6688090f447eb',
            }),
            headers: {'Content-Type': 'application/json'}
        })
        .then(response => { 
            return response.json();
        })
        .then(responseData => {
            return responseData;
        })
        .then(data1 => {
            console.log(data1)
            if (data1.tokens) {
                setLogedIn(true);
            } else {
                console.log(data1.message)
                toast({
                    title: 'Error.',
                    description: data1.message,
                    status: 'error',
                })
            }
        })
        .catch(err => {
            toast({
                title: 'Error.',
                description: "Error authenticating with smart wallet",
                status: 'error',
            })
            console.log("fetch error: " + err);
        });
    }

    return (
        <>
            {logedIn && (
                iframeScreen()
            )}

            {!logedIn && (
                <Flex height="100vh" justifyContent="center" alignItems="center">
                    <Card mt={{ base: "0px", md: "80px", xl: "80px" }} maxW='540px' mx='auto'>
                        <Flex bg={bgCard} p={4} borderRadius="lg" boxShadow="lg" align="center" width="full" flexDirection="column">
                            <Box flexShrink={0} mb='30px'>
                                <Image borderRadius="lg" width="100%" src={PayPangeaPresentation} alt="PayPangea Presentation" />
                            </Box>
                            <Box ml={{ md: 6 }}>
                                <Heading 
                                    fontSize="xl" 
                                    fontWeight="bold"
                                    mb='30px'>PayPangea Presentation</Heading>
                                <Text color={textColor} mb='15px'>
                                    Please register to access PayPangea presentation.
                                </Text>
                                <Text color={textColor} mb='15px'>
                                    You can remain anonymous by selecting to login with WEB3 Wallet Connect. Or you can easy create your own PayPangea WEB3 wallet though the process. Try it out!
                                </Text>
                                <Button onClick={() => {
                                    RegisterClick()
                                }} 
                                variant='brand' 
                                mt='20px'
                                mb='25px'
                                size="lg" 
                                w="100%">
                                 Get access
                                </Button>
                                <Text color={textColor} mb='15px'>
                                    If you want to use the same access control for your documents, visit <Link fontWeight='bold' href="https://www.blocksee.co/" color={textColor}>Blocksee.co</Link> and check for document access control with PayPangea integration.
                                </Text>
                            </Box>
                        </Flex>
                    </Card>
                </Flex>
            )}  
        </>
    );
}

export default PitchPage;
