import {useState, useEffect, useCallback} from "react";

// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  useToast,
  Button,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "../../../../../../components/card/Card.js";
import InputField from "../../../../../../components/fields/InputField";
import TextField from "../../../../../../components/fields/TextField";
import React from "react";
import { ApiHeader } from '../../../../../../_helpers/ApiHeader';

export default function Information(props) {
  const { ...rest } = props;
  const toast = useToast();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  const [apiKey, setApiKey] = useState('');



  useEffect(()=>{
    getApiKey()
  },[])


  const getApiKey = () => {

    fetch(process.env.REACT_APP_API_URL+'user/get-apikey', {
      method: 'GET',
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data.apikey)
        setApiKey(data.apikey)
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  // Chakra Color Mode
  return (
    <FormControl>
      <Card mb='20px' {...rest}>
        <Flex direction='column' mb='30px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Account API Settings
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can get your API key to connect your services. You can get instructions how to connect your services to PayPangea here:
          </Text>

          <Flex 
            color='white' 
            textAlign='left' 
            direction="row" 
            mt='30px'
            w='calc(100%)' 
            justifyContent={'space-between'}
            alignItems={'baseline'}>
              <Text fontSize='sm' fontWeight='700' mb='5px'>
                Use this API key to collect payment: 
              </Text>
              <Button
                variant="outline"
                borderRadius="full"
                mb='10px'
                size="xs"
                onClick={() => {
                  navigator.clipboard.writeText(apiKey)
                  toast({
                    title: 'Success.',
                    description: "Key copied to clipboard",
                    status: 'success',
                  })
                }}
              >
                Copy API key
              </Button>
            </Flex>

            <Text 
              fontSize='md' 
              cursor='pointer'
              color={textColorSecondary}
              onClick={() => {
                navigator.clipboard.writeText(apiKey)
                toast({
                  title: 'Success.',
                  description: "Link copied to clipboard",
                  status: 'success',
                })
              }}>
              {apiKey}
            </Text>
        </Flex>
      </Card>
    </FormControl>
  );
}
