import { Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Button } from "@chakra-ui/react";
import { CheckCircleIcon, WarningTwoIcon } from "@chakra-ui/icons";
import React from "react";

const Overlay = ({isOpen, text}) => {

  return (
    <Modal isOpen={isOpen} size="full" bg="transparent" isCentered>
        <ModalOverlay
        bg='none'
        backdropFilter='auto'
        backdropBlur='32px'/>
        <ModalContent bg="transparent" >
        <Text
            fontSize="2xl"
            fontWeight="bold"
            color="white"
            textAlign="center"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
        >
            {text}
        </Text>
    </ModalContent>
        </Modal>
  );
}

export default Overlay;
