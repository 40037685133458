

import {useState, useEffect } from "react";
// Chakra imports
import { Flex } from "@chakra-ui/react";
import { ApiHeader } from '../../../_helpers/ApiHeader';

// Custom components
import Card from "../../../components/card/Card";
import Banner from "../../../views/dashboard/transactions/components/Banner";
import Content from "../../../views/dashboard/transactions/components/Content";

export default function TransferFunds() {
  // Chakra Color Mode

  const [wallet, setWallet] = useState('')

  return (
    <Card mt={{ base: "0px", md: "80px", xl: "80px" }} maxW='920px' mx='auto'>
      <Flex direction='column' width='stretch'>
        <Banner title='Transfer' wallet={wallet}/>
        <Content />
      </Flex>
    </Card>
  );
}
