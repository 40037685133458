import { 
    Text, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    Button, 
    Flex,
    Grid,
    GridItem } from "@chakra-ui/react";
import { CheckCircleIcon, WarningTwoIcon } from "@chakra-ui/icons";
import React from "react";

import { HSeparator } from "../../../components/separator/Separator";

const SuccessModal = ({isOpen, onClose, outcome, message = ''}) => {
  return (
    <Modal 
        isCentered 
        motionPreset='slideInBottom'
        isOpen={isOpen} 
        onClose={onClose} 
        size="xs">
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropBlur='8px'/>
        <ModalContent>
            {outcome === 'SUCCESS' ? 
                <ModalHeader textAlign="center">Success</ModalHeader> :
                <ModalHeader textAlign="center">Failure</ModalHeader>
            }
            <ModalCloseButton />
            <ModalBody p={10} textAlign="center">
                {outcome === 'SUCCESS' && (
                    <>
                        <CheckCircleIcon color="green.500" boxSize={60} mx="auto" mb={2} />
                        <Text fontSize="md" mt="10px" fontWeight='regular'>
                            Your operation was successful!
                        </Text>

                        <Button onClick={onClose} 
                            variant='brand' 
                            mt="20px" 
                            size="lg" 
                            w="100%">
                            Close
                        </Button>
                    </>
                )}
                {outcome === 'FAILURE' && (
                    <>
                        <WarningTwoIcon color="red.500" boxSize={60} mb={6} />
                        <Text fontSize="md" mt="10px" fontWeight='regular'>
                            {message.length>0 ? 
                                <>{message}</> :
                                <>Something went wrong.<br></br>Please try again.</>
                            }
                        </Text>

                        <Button onClick={onClose} 
                            variant='brand' 
                            mt="20px" 
                            size="lg" 
                            w="100%">
                            Close
                        </Button>
                    </>
                )}

            </ModalBody>
          </ModalContent>
    </Modal>
  );
}

export default SuccessModal;
