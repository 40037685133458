import { useState, useEffect } from "react";
import {
  FormControl,
  FormErrorMessage,
  Box,
  Flex,
  Icon,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Flag from "react-world-flags";
import { AsYouType } from "libphonenumber-js";
import { COUNTRIES, getCountryTelCode } from "./countries";

import { AiFillFlag } from 'react-icons/ai';
import { AiOutlineCaretDown } from 'react-icons/ai';

function PhoneNumberInput(props) {

  const {
    mb,
    size,
    value,
    country,
    onChange,
    placeholder,
    isInvalid, 
    errorMessage,
    setSupported,
    ...rest} = props;
  
  const excludedCountries = ['GBR', 'GIB', 'UKR', 'SJM']; 

  const countryOptions = COUNTRIES
    .filter(({ region, subregion, iso }) => (region === 'Europe' || subregion ==='Northern America') && !excludedCountries.includes(iso))
    .map(({ name, iso }) => ({
      label: name,
      value: iso
    }));

  const isCodeInCountryOptions = (code) => countryOptions.some(option => option.value === code);


  let [number, setNumber] = useState(value || "");
  let [selectedCountry, setSelectedCountry] = useState("");
  let [countryCode, setCountryCode] = useState(getCountryTelCode(""));

  let [detectedCountry, setDetectedCountry] = useState("");
  let [isSupported, setIsSupported] = useState(false);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        console.log(data.country_name)
        if (isCodeInCountryOptions(data.country_code_iso3)) {
          setSelectedCountry(data.country_code_iso3);
          setCountryCode(getCountryTelCode(data.country_code_iso3));
        }
        setDetectedCountry(data.country_name);
        setIsSupported(isCodeInCountryOptions(data.country_code_iso3));
        setSupported(isCodeInCountryOptions(data.country_code_iso3));
      });
  }, []);

  const onCountryChange = e => {
    let value = e.target.value;
    let code = getCountryTelCode(value);
    let parsedNumber = new AsYouType().input(`${code}${number}`);

    setCountryCode(code);
    setSelectedCountry(value);
    onChange(parsedNumber);

    setSupported(e.target.value!=='');
  };

  const onPhoneNumberChange = e => {
    let value = e.target.value;
    let parsedNumber = new AsYouType().input(`${countryCode}${value}`);

    setNumber(value);
    onChange(parsedNumber);
    setSupported(countryCode!=='');
  };

  return (
    <>
    <FormControl 
      mb={mb ? mb : "30px"}
      isInvalid={isInvalid}>

      <InputGroup size={size} {...rest}>
        <InputLeftElement width="4rem">
          <Select
            icon={<AiOutlineCaretDown />}
            width={'6rem'}
            top="10px"
            left="0"
            zIndex={1}
            bottom={0}
            opacity={0}
            height="100%"
            position="absolute"
            value={selectedCountry}
            style={{ appearance: "none" }}
            onChange={onCountryChange}
          >
            <option value="" />
            {countryOptions.map((option, i) => (
              <option key={i} value={option.value}>{option.label}</option>
            ))}
          </Select>
          <Flex pl={2} width="100%" alignItems="center">
            {selectedCountry ? (
              <Box mr="4px" width="50%" flex={1}>
                <Flag height="1rem" code={selectedCountry || ""} />
              </Box>
            ) : (
              <Icon as={AiFillFlag} />
            )}
          </Flex>
        </InputLeftElement>
        <Input
          pl="6rem"
          type="tel"
          value={number}
          pattern="[0-9]"
          color={textColorPrimary}
          placeholder={placeholder}
          onChange={onPhoneNumberChange}
        />
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>

    {!isSupported && (
      <Text fontSize="md" mt="10px" mb="20px" align="left" fontWeight='regular'>
          We detected that you connect from <b>{detectedCountry}</b>. The country is unfortunatelly not supported for phone login yet. Please use email or Google login instead.
      </Text>
    )}


    {isSupported && (
      <Text fontSize="md" mt="10px" mb="40px" align="left" fontWeight='regular'>
          By clicking the Start button below you consent to receive an sms with one time passcode to access our services.
      </Text>
    )}

    </>
    
  );
}

export default PhoneNumberInput;
